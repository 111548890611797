<template>
    <div>
        <div class="body-w100 d-flex justify-content-between">
            <div class="col-12 col-md-4" style="padding-right:20px">
                <v-chart v-if="loaded" :autoresize="true" class="chart" :option="nb_clinical_trials" @click="filterStatus"></v-chart>
            </div>
            <div class="col-12 col-md-8">
                <v-chart v-if="loaded" :autoresize="true" class="chart" :option="clinical_trials" @click="filterByAgency"></v-chart>
            </div>
        </div>

        <div class="body-w100 d-flex justify-content-between">
            <div class="col-12 col-md-6" style="padding-right:20px">
                <v-chart v-if="loaded" :autoresize="true" class="chart" :option="data_therapeutic" @click="filterByTherapeutic"></v-chart>
            </div>
            <div class="col-12 col-md-6">
                <v-chart v-if="loaded" :autoresize="true" class="chart" :option="data_brandname" @click="filterByName"></v-chart>
            </div>
        </div>
    </div>
</template>
    
<script>

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
} from "echarts/components";
import VChart from "vue-echarts";
import _ from 'lodash'

use([
  CanvasRenderer,
  BarChart,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
]);

export default {
    name: "TabClinicalTrialsGraph",
    components: {
        VChart
    },
    data() {
        return {
            clinical_trials: {
                title: {
                    text: 'Clinical trials',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    bottom: '3%',
                    show: false
                },
                grid: {
                    bottom: '0%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLabel: {
                        rotate: 45
                    }
                },
                yAxis: {
                    type: 'value',
                },
                series: [{
                    type: 'bar',
                    data: [],
                    label: {
                        show: true
                    }
                }
    
                ]
            },
            nb_clinical_trials: {
                title: {
                    text: 'Number of clinical trials',
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    bottom: '3%',
                    left: 'center'
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        data: []
                    }
                ]
            },
            data_therapeutic: {
                title: {
                    text: 'Therapeutic area',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    bottom: '3%',
                    data: ['Published', 'In development']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'value'
                },
                yAxis: {
                    type: 'category',
                    data: [],
                    inverse: true
                },
                series: [
                    {
                        name: 'Published',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        barMinHeight: '50px',
                        data: []
                    },
                    {
                        name: 'In development',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        barMinHeight: '50px',
                        data: [],
                        itemStyle: {
                            color: '#ff6204'
                        }
                    }
                ]
            },
            data_brandname: {
                title: {
                    text: 'Brand name',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    bottom: '3%',
                    data: ['Published', 'In development']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'value'
                },
                yAxis: {
                    type: 'category',
                    data: [],
                    axisLabel: {
                        formatter: function(value) {
                            const strSplitted = value.split('(')
                            if(strSplitted.length > 1 && !strSplitted[1].includes('+'))
                                return strSplitted[0] + ' {a|(' + strSplitted[1] + '}'
                            else if(strSplitted.length > 2 && strSplitted[1].includes('+')) {
                                let category_name = strSplitted[0] + ' (' + strSplitted[1]
                                let remainingStr = strSplitted
                                remainingStr.splice(0,2)
                                return category_name + ' {a|(' + remainingStr.join(' (') + '}'
                            } else 
                                return value
                        },
                        rich: {
                            a: {
                                fontSize: '8px',
                                fonStyle: 'italic'
                            },
                        }
                    },
                    inverse: true
                },
                series: [
                    {
                        name: 'Published',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        barMinHeight: '50px',
                        data: []
                    },
                    {
                        name: 'In development',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        barMinHeight: '50px',
                        data: [],
                        itemStyle: {
                            color: '#ff6204'
                        }
                    }
                ]
            },
            loaded: false
        }
    },
    methods: {
        setDataChart(){
            let therapeutic_area = []
            let brand_name = []
            let unique_ncts = []
            let total_published = 0
            let total_in_development = 0

            this.resetValues()

            if(this.data && !_.isEmpty(this.data)){
                this.loaded = true
                for (const [nct, item] of Object.entries(this.data)) {
                    for (const [agency_model, i] of Object.entries(item)) {
                        const type = (agency_model === 'In development') ? 'in_development' : 'published'
                        const agency = (agency_model === 'In development') ? agency_model : i.acronym
                        const development_color = (agency_model === 'In development') ? {value: 1,itemStyle: {color: '#ff6204'}} : 1
                        if(!this.clinical_trials.xAxis.data.includes(agency)){
                            this.clinical_trials.xAxis.data.push(agency)
                            this.clinical_trials.series[0].data.push(development_color)
                        } else {
                            if(agency_model === 'In development')
                                this.clinical_trials.series[0].data[this.clinical_trials.xAxis.data.indexOf(agency)].value += 1
                            else
                                this.clinical_trials.series[0].data[this.clinical_trials.xAxis.data.indexOf(agency)] += 1
                        }

                        

                        if(!unique_ncts.includes(nct)) {
                            if(agency_model === 'In development')
                                total_in_development += 1
                            else
                                total_published += 1
                            unique_ncts.push(nct)
                        }

                        i.indexation.forEach(index => {
                            if(!(index in therapeutic_area)) {
                                therapeutic_area[index] = {published: 0, in_development: 0}
                                therapeutic_area[index][type] += 1
                            } else
                                therapeutic_area[index][type] += 1
                        })
                        if(i.category !== ""){
                            if(!(i.category in brand_name)) {
                                brand_name[i.category] = {published: 0, in_development: 0}
                                brand_name[i.category][type] += 1
                            } else
                                brand_name[i.category][type] += 1
                        }
                    }
                }
            }
            

            this.sortAndAssignValues(therapeutic_area, 'therapeutic')
            this.sortAndAssignValues(brand_name, 'brandname')

            this.nb_clinical_trials.series[0].data.push({name: 'Published', value: total_published}) 
            this.nb_clinical_trials.series[0].data.push({name: 'In development', value: total_in_development, itemStyle: {color: '#ff6204'}})
        },

        getModelByAcronym(acronym) {
            for (const item of Object.values(this.data)) {
                for (const [agency, i] of Object.entries(item)) {
                    if(i.acronym === acronym)
                        return agency
                }
                   
            }
        },

        sortAndAssignValues(values, type) {

            const data = this['data_'+type]
            
            Object.entries(values).sort(function (a, b) {
                const totalA = a[1].in_development + a[1].published
                const totalB = b[1].in_development + b[1].published
                if(totalA < totalB)
                    return 1
                if (totalA > totalB)
                    return -1
                return 0;
            })
            .forEach((indexation, i) => {
                if(i < 10){
                    if(!data.yAxis.data.includes(indexation[0]))
                        data.yAxis.data.push(indexation[0])
                    data.series.find(s => s.name === 'In development').data.push(indexation[1].in_development)
                    data.series.find(s => s.name !== 'In development').data.push(indexation[1].published)
                }
            })
        },

        filterByAgency(params) {
            const agency_model = (params.name === 'In development') ? params.name : this.getModelByAcronym(params.name)
            this.$emit('request_data', {agency: agency_model, graph_filter:true})
        },
        filterByTherapeutic(params) {
            const therapeutic = params.name
            this.$emit('request_data', {therapeutic, graph_filter:true})
        },
        filterByName(params) {
            const name = params.name
            this.$emit('request_data', {name, graph_filter:true})
        },
        resetValues() {
            this.clinical_trials.series[0].data = []
            this.clinical_trials.xAxis.data = []
            this.nb_clinical_trials.series[0].data = []
            this.data_therapeutic.series.forEach(serie => { serie.data = [] })
            this.data_therapeutic.yAxis.data = []
            this.data_brandname.series.forEach(serie => { serie.data = [] })
            this.data_brandname.yAxis.data = []
        },
        filterStatus(params) {
            const status = params.name
            this.$emit('request_data', {status, graph_filter:true})
        },
    },

    computed: {
        data(){
            return this.$store.getters['essais_clinique/data_drug_centers'];
        },
    },

    mounted() {
        this.setDataChart()
    },

    watch: {
        data: function() {
            this.setDataChart()
        }
    }
}
</script>
<style scoped>
    .chart {
        height: 400px;
        background: #fff;
        padding: 20px;
        margin-bottom: 20px;
        min-width: 100%
    }
</style>