<template>
    <div>
        <Header headerType="advance_result"/>


        <div :class="'container-fluid' + ($route.query.zoom ? ' fullscreen' : '') + (isUserFrench ? ' page-french' : '') ">
            <div class="row">
                <div class="main-box-fixed">                        
                    <div class="box-fixed" >
                        <div class="title">
                            <h1>{{ count }}</h1>
                            <h2>Résultats</h2>
                        </div>
                    </div>
                    <div class="advance box-fix-left" ref="menuFlag"  style="top:0px;">
                        <ul>
                            <label class="flag-active">  
                                                             
                                <li class="active pointerNone" >
                                   <img :src="require('@/assets/images/flag/new/fr.svg')" width="35" height="35" style="margin-bottom: 5px;" />
                                    <p> 
                                        <span style="margin-right: 0;"></span>
                                        {{$t('TC')}}
                                    </p>
                                    <p class="short_name">(FRA)</p> 
                                </li>                                
                            </label>

                             

                        </ul>
                    </div>
                </div>

                <div class="main-body">
                    <div class="body-fix">
                        <div class="body-custom">
                            <div class="box-btn-menu">  
                                <a href="javascript:void(0)" class="btn btn-border-orange btn-muted pointerNone">{{$t('Overview')}}</a>
                                <a href="javascript:void(0)" @click="selectTab('expert')" :class="'btn ' + (!$route.query.tab || $route.query.tab == 'expert' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Expert')}}</a>
                                <a v-if="user && user.access_clinical" href="javascript:void(0)" @click="selectTab('essais_clinique')" :class="'btn ' + ($route.query.tab && $route.query.tab == 'essais_clinique' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Clinical trials')}}</a>
                                <a href="javascript:void(0)" @click="selectTab('prix')" :class="'btn ' + ($route.query.tab && $route.query.tab == 'prix' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Prix')}}</a>
                            </div>
                        </div>
                    </div>

                    <div v-if="(!$route.query.tab || $route.query.tab == 'expert')">    
                    <div class="body-w100" v-if="data.data && data.data.length > 0">
                        <div class="box-detail">
                            <!-- <div class="wrapper-box-hta">
                                <h1 class="fa-pull-left mr-4 mt-1">Reimbursement Status</h1>
                                <div class="clearfix"></div>
                            </div>  -->
                            <ModalConfigColumn :page="page" :type="type" :column="orderColumn" :reset_column="reset_column" />    
                            <ModalSaveKeyword :type="type" />                                           
                            <IconTopTable @onRequest="request_data" :page="page" @onSelectExport="selectExport" @onClearExport="clearExport"/>
                            <div class="content-table-results mt-2">
                                <table class="table table-fixed table-stripes"  >
                                    <ColumnColGroup :page="page" :column="orderColumn" :default_column="default_column"  />
                                    <ColumnThead :page="page" :column="orderColumn" :default_column="default_column"  />  
                                    <tbody> 
                                        <template v-for="(item, key) in data.data" >
                                        <tr v-for="(sub_item, sub_key) in max_row(item)" :key="key+'_'+sub_key" :class="odd_even_border(key, sub_key)" >                
                                            
                                            <td>
                                                <template v-if="sub_key===0">
                                                    <label class="table-check">
                                                        <input type="checkbox"  v-model="select_export" :value="item.drug_id">
                                                        <span class="checkbox-table"></span>
                                                    </label>
                                                </template>
                                            </td>
                                            <td>
                                                <template v-if="sub_key===0">
                                                <span v-html="icon_link(item.drug_id,'trs')"></span>
                                                <div v-if="isAdmin">
                                                    <a :href="modifyPage('transparences', item.drug_id)" target="_blank" rel="noopener"> 
                                                        <em class="icon-edit"></em>
                                                    </a>
                                                </div>
                                                </template>
                                            </td>
                                            <template v-for="(column) in orderColumn" :key="column.code" >
                                                <td v-if="column.Status && column.code == 'transparence_ceesp_001'" :code="column.code">
                                                    <span v-if="sub_key===0">{{item.drug_id}}</span>                                                    
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_002'" :code="column.code">
                                                    <span v-if="sub_key===0" v-html="check_empty(array(item.indexation))"></span>
                                                </td>                                                
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_003'" :code="column.code">
                                                   <span v-html="column_status(item)" v-if="sub_key===0"></span>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_004'" :code="column.code">
                                                    <template v-if="sub_key===0">{{check_empty(item.dci)}}</template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_005'" :code="column.code">
                                                    <template v-if="sub_key===0">{{check_empty(item.name)}}</template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_006'" :code="column.code">
                                                    <template v-if="sub_key===0">{{check_empty(item.firm)}}</template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_007'" :code="column.code">
                                                    <template v-if="sub_key===0"> 
                                                        <div v-if="item.transparence.trs_type_demandes" v-html="$t('transparence.trs_type_demandes.'+item.transparence.trs_type_demandes)"></div>
                                                        <div v-if="item.transparence.raison_reevaluation" v-html="$t('transparence.raison_reevaluation.'+item.transparence.raison_reevaluation)"></div>
                                                        <span v-if="!item.transparence.raison_reevaluation && !item.transparence.trs_type_demandes"> - </span>
                                                    </template> 
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_008'" :code="column.code">
                                                    <template v-if="sub_key===0">{{check_empty(item.transparence.trs_motif)}}</template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_009'" :code="column.code">
                                                    <template v-if="item.transparence.transparence_smr && item.transparence.transparence_smr[sub_key]">
                                                        <div v-html="smr_type(item.transparence.transparence_smr[sub_key]['smr_type'])" ></div>
                                                    </template> 
                                                    <span v-else> - </span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_010'" :code="column.code">
                                                    {{ item.transparence.transparence_smr && item.transparence.transparence_smr[sub_key] ? check_empty_with_locale(item.transparence.transparence_smr[sub_key]['asmr_obtenue'], 'transparence.asmr.') : '-'}}
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_017'" :code="column.code">
                                                    <template v-if="sub_key===0">{{ check_empty_with_locale(item.transparence.quality_of_life_data)}}</template>                                                    
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_018'" :code="column.code">
                                                    <template v-if="sub_key===0"> 
                                                        <Readmore v-if="$i18n.locale === 'fr'" :longText="item.transparence.type_of_qol_data_presented"/> 
                                                        <Readmore v-else :longText="item.transparence.type_of_qol_data_presented_en"/> 
                                                     </template>                                                    
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_020'" :code="column.code">
                                                    <template v-if="sub_key===0">{{ check_empty_with_locale(item.transparence.accepted_qol)}}</template>                                                    
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_021'" :code="column.code">
                                                    <template v-if="sub_key===0">{{ check_empty_with_locale(item.transparence.indirect_comparison_data)}}</template>                                                    
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_022'" :code="column.code">
                                                    <template v-if="sub_key===0">{{ check_empty_with_locale(item.transparence.acepted_ic)}}</template>                                                    
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_023'" :code="column.code">
                                                    <template v-if="sub_key===0"> 
                                                        <Readmore v-if="$i18n.locale === 'fr'" :longText="item.transparence.population_ic"/>  
                                                        <Readmore v-else :longText="item.transparence.population_ic_en"/>  
                                                    </template>                                                    
                                                </td>

                                                
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_011'" :code="column.code">
                                                    <template v-if="temp_ceesp[item.drug_id]  && temp_ceesp[item.drug_id][sub_key]">
                                                         {{ check_empty(DDMMMYY(temp_ceesp[item.drug_id][sub_key]['date_avis_guidance']))}}
                                                    </template> 
                                                     <!-- <template v-if="temp_ceesp[item.drug_id]  && temp_ceesp[item.drug_id][sub_key] && temp_ceesp[item.drug_id][sub_key]['date']">
                                                         {{ DDMMMYY(temp_ceesp[item.drug_id][sub_key]['date'])}}
                                                    </template>  -->
                                                    <span v-else> - </span>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_012'" :code="column.code">
                                                    <template v-if="temp_ceesp[item.drug_id] && temp_ceesp[item.drug_id][sub_key]">
                                                        {{ check_empty_with_locale(temp_ceesp[item.drug_id][sub_key]['impact_significatif'], 'yes_no_')}}
                                                    </template> 
                                                    <span v-else> - </span>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_013'" :code="column.code">
                                                    <template v-if="temp_ceesp[item.drug_id] && temp_ceesp[item.drug_id][sub_key]">
                                                        {{ check_empty_with_locale(temp_ceesp[item.drug_id][sub_key]['conclusion'], "conclusion_")}}
                                                    </template> 
                                                    <span v-else> - </span>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_014'" :code="column.code">  
                                                    <template v-if="temp_ceesp[item.drug_id] && temp_ceesp[item.drug_id][sub_key]">
                                                        {{check_empty_with_locale(temp_ceesp[item.drug_id][sub_key]['appreciation'], "appreciation.")}}
                                                    </template> 
                                                    <span v-else> - </span>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'transparence_ceesp_015'" :code="column.code">
                                                    <template v-if="temp_ceesp[item.drug_id]  && temp_ceesp[item.drug_id][sub_key]">
                                                        <template v-if="$i18n.locale !== 'fr'"> 
                                                            <Readmore  :longText="temp_ceesp[item.drug_id][sub_key]['reverseen']"/>
                                                        </template> 
                                                        <template v-else>  
                                                            <Readmore  :longText="temp_ceesp[item.drug_id][sub_key]['reversefr']"/>
                                                        </template> 
                                                    </template>   
                                                    <span v-else> - </span> 
                                                </td> 
                                                 <td v-else-if="column.Status && column.code == 'transparence_ceesp_016'" :code="column.code">
                                                     <template v-if="temp_ceesp[item.drug_id]  && temp_ceesp[item.drug_id][sub_key]">
                                                         {{ check_empty(DDMMMYY(temp_ceesp[item.drug_id][sub_key]['date']))}}
                                                    </template> 
                                                </td> 
                                            </template>

                                        </tr>
                                        </template>
                                    </tbody>

                                </table>
                            </div>
                            <!---- Area Table Result End----->
                        </div>
                    </div> 

                    <div v-else class="body-w100" >
                        <div class="box-detail">
                            <div class="wrapper-box-hta">
                                <h1 class="fa-pull-left mr-4 mt-1">{{$t('No Data')}}</h1>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>   
                    </div>

                    <TablePrix v-if="$route.query.tab == 'prix'" main_page="TransparenceCeesp" />

                    <TableEssaisClinique v-if="$route.query.tab == 'essais_clinique'" main_page="TransparenceCeesp" />

                </div>
            </div>
        </div>


        <Loader :isLoad="isLoad" />
        <Footer />
    </div>
</template>

<script>

import Header from '@/components/Layout/Header.vue'; 
import Footer from '@/components/Layout/Footer.vue';
import IconTopTable from '@/components/elements/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue'; 
import Readmore from '@/components/elements/readmore.vue';
import ModalConfigColumn from '@/components/elements/modal_config_column.vue';
import ModalSaveKeyword from '@/components/elements/modal_save_keyword.vue';
import TablePrix from '@/components/advanced_result/table_prix.vue';
import TableEssaisClinique from '@/components/advanced_result/table_essais_clinique.vue';
import { column_status, eventHandlerResize, currentDate, isAdmin, isUserFrench, icon_link, 
        check_empty, DDMMMYY, modifyPage, check_empty_with_locale, array, odd_even_border  } from '@/utils'; 
import Loader from "@/components/Layout/Loader";

export default {
    name: 'advanced_result_transparence_ceesp',
    components: {        
        Header,
        Footer,   
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        Readmore,
        ModalConfigColumn,
        ModalSaveKeyword,
        TablePrix,
        TableEssaisClinique,
        Loader
    },
    data() {
        return {
            page : "transparence_ceesp",
            temp_ceesp: []
        }
    },
    mounted() {
        //search 
        this.request_data({ mounted : true, order_by : 'decision_date_num', sort : 'desc' })
        document.body.classList.add('body-bg-gray')
        if(this.$route.query.zoom){
            this.eventHandlerResize()
        }
    },
    methods: {
        request_data(param = {}){
            let paramObj = {...this.$route.params}
            paramObj['base64Search'] = paramObj['base64Search'].replace('/\-/g', '+').replace('/_/g', '/') // eslint-disable-line
            if(!param['mount'] && this.sort_column && Object.keys(this.data).includes(this.sort_column['order_by'])){
                paramObj['order_by'] = this.sort_column['order_by']
            }
            else if(param['order_by']){
                paramObj['order_by'] = param['order_by']
            }
            else{
                paramObj['order_by'] = 'decision_date_num'
            }
            paramObj['sort'] = !param['mounted'] && this.sort_column && this.sort_column['sort'] ? this.sort_column['sort'] : param['sort']
            if(param.page){
                paramObj['page'] = param.page
            }
            if(this.select_export_obj.isSelect){
                paramObj['select_export'] = this.select_export_obj.listID
            }
            if(this.data_filter !== ""){
                paramObj['filter'] = this.data_filter
            }
            if(this.$route.query.keyword_id){
                paramObj['keyword_id'] = this.$route.query.keyword_id
            }
            if(param.export){
                delete param.export;
                this.$store.dispatch(`advanced_result/result_transparence_ceesp_excel`, [paramObj, currentDate()+"_advanced_transparence_ceesp" ] ) 
            }else{
                this.$store.dispatch(`advanced_result/result_transparence_ceesp`, paramObj ) 
            }

            if(!param.mounted){
                this.selectTab('expert')
            }
        },  
        icon_link,
        check_empty,
        DDMMMYY,
        check_empty_with_locale,
        array,
        selectTab(tab){
            let query = Object.assign({}, this.$route.query);
            if(query.tab !== tab){
                query.tab = tab            
                console.log(this.$route.query)
                this.$router.push({ query });  
            }                       
        },
        selectExport(){
            this.$store.dispatch("advanced_result/select_export", { key : "isSelect" , value : true})                 
            this.request_data() 
        },
        clearExport(){
            this.$store.dispatch("advanced_result/select_export", "clear")                      
            this.request_data()
        },
        column_status,
        handleClickBoxSearch(){
            this.$store.dispatch("header/setOpenBoxSearch", !this.openBoxSearch)
        }, 
        smr_type(smr_type){
            let class_color = "green";
            let text = "";
            if(smr_type){                
                if(smr_type == "insuffisant"){
                    class_color = "red";
                }
                if(smr_type == "Remboursement non sollicité par la firme"){
                    class_color = "";
                }
                text = "<span class="+class_color+">"+ this.$t("transparence.smr."+smr_type)+"</span>"
            }
            text = this.check_empty(text)
            return text

        },
        array_ceesp : function(param){  
            const re_data = [];   
            for(const key in param){  
                if(param[key]['ceesp_titleappreserve'].length > 0){
                    for(const keys in param[key]['ceesp_titleappreserve']){  
                        var date = null;
                        var date_avis_guidance = null;
                        var impact_significatif = null;
                        var conclusion = null;
                        if(keys==0){
                            date = param[key]['date'];
                            date_avis_guidance = param[key]['date_avis_guidance'];
                            impact_significatif = param[key]['impact_significatif'];
                            conclusion = param[key]['conclusion'];
                        }
                        re_data.push({ 
                            date: date,
                            date_avis_guidance: date_avis_guidance,
                            impact_significatif: impact_significatif,
                            conclusion: conclusion,
                            appreciation: param[key]['ceesp_titleappreserve'][keys]['appreciation'],
                            reverseen: param[key]['ceesp_titleappreserve'][keys]['reverseen'],
                            reversefr: param[key]['ceesp_titleappreserve'][keys]['reversefr']
                        })
                    }
                }else{
                    re_data.push({ 
                        date: param[key]['date'],
                        date_avis_guidance: param[key]['date_avis_guidance'],
                        impact_significatif: param[key]['impact_significatif'],
                        conclusion: param[key]['conclusion'],
                        appreciation: null,
                        reverseen: null,
                        reversefr: null
                    })
                }
            }
            return re_data;
        },
        max_row(param){
            const temp = [];
            temp.push(1);     
            if(param.transparence.transparence_smr){
                temp.push(param.transparence.transparence_smr.length)
            } 
            if(param.link_ceesp){
                temp.push(this.array_ceesp(param.link_ceesp).length);
            }  
            return Math.max(...temp)
        },
        eventHandlerResize,
        modifyPage,
        odd_even_border
       
    },
    computed: {        
        isAdmin,
        isUserFrench,
        user(){
            return this.$store.getters['auth/user']
        },
        data(){
            return this.$store.getters['advanced_result/data'];
        },
        orderColumn(){
            return this.$store.getters['advanced_result/column'];
        },
        default_column(){
            return this.$store.getters['advanced_result/default_column'];
        },
        type(){
            return this.$store.getters['advanced_result/type'] 
        },
        reset_column(){
            return this.$store.getters['advanced_result/reset_column']                       
        }, 
        openBoxSearch () {  
            return this.$store.getters['header/openBoxSearch']
        },                 
        count() {
            return this.$store.getters['advanced_result/count']
        },
        isLoad() {  
            if(this.$store.getters['advanced_result/load'] || this.$store.getters['advanced_result/prix_load'] || this.$store.getters['essais_clinique/load']){
                return true
            }
            return false
        },
        select_export: {
            set(val){ this.$store.dispatch("advanced_result/select_export", {key: 'listID', value: val}) },
            get(){ 
                if(this.$store.getters['advanced_result/select_export'].checkAll){
                    return true
                }else{
                    return this.$store.getters['advanced_result/select_export'] ? this.$store.getters['advanced_result/select_export'].listID : null
                }
            }
        },
        select_export_obj(){
            return this.$store.getters['advanced_result/select_export']
        },
        data_filter(){
            return this.$store.getters['advanced_result/data_filter']
        },
        sort_column(){
            return this.$store.getters['advanced_result/sort_column']
        }
    },
    watch:{
        data: function (newData) {
            let result = {}
            for(let item of newData.data){ 
                if(item.link_ceesp){
                    result[item.drug_id] = this.array_ceesp(item.link_ceesp)
                }
            }
            this.temp_ceesp = result
        }
    },
    created() {      
        if(this.$route.query.zoom){
            window.addEventListener("resize", this.eventHandlerResize);
        }
    },
    unmounted () {
        document.body.classList.remove('body-bg-gray'); 
        this.$store.dispatch("advanced_result/clearState") 
        this.$store.dispatch("essais_clinique/clearState")
        if(this.$route.query.zoom){
            window.removeEventListener("resize", this.eventHandlerResize);
        }
    }
}
</script>

<style scoped>
.main-body{
    min-height: 654px;
}
.box-fix-left{ padding-top:133px}
/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 60vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>