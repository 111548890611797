<template>
    <teleport to="body">
    <div class="modal fade" id="modal-config-column" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content modal-custom p-4">
                <div>
                    <div class="box-modal-head mb-2">
                        <h5 class="mb-0">Choisir les colonnes à afficher :</h5>
                        <div> 1. Sélectionnez les rubriques que vous désirez afficher dans le tableau.<br>
                            2. Faites glisser les rubriques pour réorganiser les colonnes du tableau.
                        </div>
                    </div>
                    <a type="button" class="close" data-bs-dismiss="modal" aria-label="Close" style="opacity: 1;">
                        <span class="close-column"></span>
                    </a>
                </div>
                <div class="modal-body p-0">                    

                    <div class="row">
 
                        <draggable class="col-12 col-lg-4 col-md-4 col-sm-4" :list="data_column[0]" group="main_a" v-bind="dragOptions" @end="onEnd" itemKey="title">
                            <template #item="{ element }">
                            <div class="box-border-gray-collapse" >  
                                <label class="group-checkbox" style="cursor: pointer;margin:0">
                                    <div class="main-dd dd-handle move">
                                        <div class="icon-draggable"></div>
                                    </div>
                                    <input type="checkbox" v-model="selectAll[element.key]" @click="onSelectAll(element.key)">
                                    <span class="icon-checkbox"></span>
                                </label>
                                <a class="text-title text-title-collapse" data-bs-toggle="collapse" :box_key="element.key" :href="'#'+element.key" @click="activeCollapsed" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    {{element.title}} <span class="bt-icon-down fa-pull-right"><em :class="'fa fa-chevron-down '+element.key"></em></span>
                                </a>                                                               
                                <div :id="element.key" class="mt-2 collapse">  
                                <draggable :list="element.column" class="checkbox-list-li" :group="element.dynamic_column ? 'a' : 'a'+element.key"  v-bind="dragOptions" @end="onEnd" itemKey="code"> 
                                    <template #item="{ element }">
                                    <li class="list-li-item" >     
                                        <label class="group-checkbox" style="cursor: pointer;">
                                            <div class="dd-handle move">
                                                <div class="icon-draggable"></div>
                                            </div>
                                            <input type="checkbox" v-model="itemActive" :value="element.code" :id="element.code" @change="onSelectColumn()">
                                            <span class="icon-checkbox"  ></span>
                                            <div>{{element.text}}</div> 
                                            
                                        </label>
                                    </li>                                    
                                    </template>
                                </draggable>
                                </div>
                            </div>
                            </template>
                        </draggable>
                        

                        <draggable class="col-12 col-lg-4 col-md-4 col-sm-4" :list="data_column[1]" group="main_a" v-bind="dragOptions" @end="onEnd" itemKey="title">
                            <template #item="{ element }">
                            <div class="box-border-gray-collapse" >   
                                <label class="group-checkbox" style="cursor: pointer;margin:0">
                                    <div class="main-dd dd-handle move">
                                        <div class="icon-draggable"></div>
                                    </div>
                                    <input type="checkbox" v-model="selectAll[element.key]" @click="onSelectAll(element.key)">
                                    <span class="icon-checkbox"></span>
                                </label>
                                <a class="text-title text-title-collapse" data-bs-toggle="collapse" :box_key="element.key" :href="'#'+element.key" @click="activeCollapsed" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    {{element.title}} <span class="bt-icon-down fa-pull-right"><em :class="'fa fa-chevron-down '+element.key"></em></span>
                                </a> 
                                <div :id="element.key" class="mt-2 collapse"> 
                                <draggable :list="element.column" :group="element.dynamic_column ? 'a' : 'a'+element.key" class="checkbox-list-li" v-bind="dragOptions" @end="onEnd" itemKey="code"> 
                                    <template #item="{ element }">
                                    <li class="list-li-item" >    
                                        <label class="group-checkbox" style="cursor: pointer;">
                                            <div class="dd-handle move">
                                                <div class="icon-draggable"></div>
                                            </div>
                                            <input type="checkbox" v-model="itemActive" :value="element.code" :id="element.code" @change="onSelectColumn()">
                                            <span class="icon-checkbox"  ></span>
                                            <div>{{element.text}}</div>
                                        </label>
                                    </li>
                                    </template>
                                </draggable>
                                </div>
                            </div>
                            </template>
                        </draggable>

                        <draggable class="col-12 col-lg-4 col-md-4 col-sm-4" :list="data_column[2]" group="main_a" v-bind="dragOptions" @end="onEnd" itemKey="title">
                            <template #item="{ element }">
                            <div class="box-border-gray-collapse"> 
                                <label class="group-checkbox" style="cursor: pointer;margin:0">
                                    <div class="main-dd dd-handle move">
                                        <div class="icon-draggable"></div>
                                    </div>
                                    <input type="checkbox" v-model="selectAll[element.key]" @click="onSelectAll(element.key)">
                                    <span class="icon-checkbox"></span>
                                </label>
                                <a class="text-title text-title-collapse" data-bs-toggle="collapse" :box_key="element.key" :href="'#'+element.key" @click="activeCollapsed" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    {{element.title}} <span class="bt-icon-down fa-pull-right"><em :class="'fa fa-chevron-down '+element.key"></em></span>
                                </a>
                                <div :id="element.key" class="mt-2 collapse"> 
                                <draggable :list="element.column" class="checkbox-list-li" :group="element.dynamic_column ? 'a' : 'a'+element.key" v-bind="dragOptions" @end="onEnd" itemKey="code"> 
                                    <template #item="{ element }">
                                    <li class="list-li-item" >     
                                        <label class="group-checkbox" style="cursor: pointer;">
                                            <div class="dd-handle move">
                                                <div class="icon-draggable"></div>
                                            </div>
                                            <input type="checkbox" v-model="itemActive" :value="element.code" :id="element.code" @change="onSelectColumn()" >
                                            <span class="icon-checkbox"  ></span>
                                            <div>{{element.text}}</div> 
                                            
                                        </label>
                                    </li>
                                    </template>
                                </draggable>
                                </div>
                            </div>
                            </template>
                        </draggable>


                    </div>    

                </div>
                <div class="bt-footer">
                    <button type="button" class="btn-border-orange" style=" margin-right: 40px" @click="resetColumn">Rétablir</button>
                    <button type="button" :class="'btn-orange' + (canSave ? '' : ' btn-muted pointerNone') "  @click="saveConfigColumn">Enregistrer</button>
                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>

<script>
import { Modal } from 'bootstrap';
import draggable from "vuedraggable";
export default {
    name : "ConfigColumnAdvance",
    components:{
        draggable,        
    }, 
    props: {
        column: Array,
        reset_column: Array,
        type: String
    },
    data() {
        return { 
            original_column : [],
            data_column : [],
            itemActive : [], 
            canSave : false,
            selectAll : {},
            modal : null,
            list1: [
                { name: "John", id: 1 },
                { name: "Joao", id: 2 },
                { name: "Jean", id: 3 },
                { name: "Gerard", id: 4 }
            ],
            list2: [
                { name: "Juan", id: 5 },
                { name: "Edgard", id: 6 },
                { name: "Johnson", id: 7 }
            ]
        }
    },
    mounted() {    
        this.modal = new Modal(document.getElementById('modal-config-column'));
        this.setItemDraggable(this.column, true)
        this.onSelectColumn()
    },
    methods: { 
        setItemDraggable(data_column, begin){   
             
            let tmp_column = [];
            let tmp_column2 = [];
            let count_row = 0;
            let selectAll = {}
            this.itemActive = []
            const itemActive = []
            for(let temp of data_column){                

                for(let temp2 of temp['column']){ 
                    if(temp2['Status']){
                        itemActive.push(temp2.code)
                    }
                }

                if(!tmp_column[count_row]){
                    tmp_column[count_row] = []
                    tmp_column2[count_row] = []
                }
                selectAll[temp.key] = false

                const temp2 = {...temp}
                temp2.column = [...temp.column] 
                tmp_column[count_row].push(temp2)

                
                const temp3 = {...temp}
                temp3.column = [...temp.column] 
                tmp_column2[count_row].push(temp3)

                count_row++;
                if(count_row == 3 ){
                    count_row = 0;
                }
            }
            this.selectAll = selectAll;
            this.data_column = tmp_column;
            if(begin){                
                this.original_column = tmp_column2;
            }
            this.itemActive = itemActive; 
  
        },
        onSelectColumn(){
            this.data_column.forEach((data_column_val) => {
                data_column_val.forEach((val) => {                    
                    let isCheckAll = true
                    val.column.forEach((column) => {                                
                        const position = this.itemActive.indexOf(column.code);                           
                        if (position === -1) {
                            isCheckAll = false; 
                        }                                                          
                    }); 
                    this.selectAll[val.key] = isCheckAll
                });
            }); 
        },
        onSelectAll(column_key){       
            this.canSave = true
            this.data_column.forEach((data_column_val) => {
                data_column_val.forEach((val) => {                        
                    if(val.key === column_key){
                        val.column.forEach((column) => {                                
                            const position = this.itemActive.indexOf(column.code);
                            if (!this.selectAll[column_key]) {
                                if (position === -1) {
                                    this.itemActive.push(column.code)
                                }
                            }else{                                    
                                if (position > -1) {
                                    this.itemActive.splice(position, 1);
                                }
                            }                                
                        });   
                    }                                
                });
            }); 
        },
        activeCollapsed(event) {
            var str = event.target.className;
            let arrow;
            if( str.includes("collapsed")) {
                arrow = document.getElementsByClassName(event.target.getAttribute('box_key'));
                arrow[0].className = arrow[0].className.replace(/fa-chevron-up/g, "fa-chevron-down");
            }else{
                arrow = document.getElementsByClassName(event.target.getAttribute('box_key'));
                arrow[0].className = arrow[0].className.replace(/fa-chevron-down/g, "fa-chevron-up");
            }
        },        
        resetColumn(){
            this.setItemDraggable(this.reset_column, true);
            this.onSelectColumn();
        },
        saveConfigColumn(){
            if(this.canSave){
                ///console.log(this.data_column)
                let objColumn = {}; 


                for (let i = 0; i < 10; i++) {
                    this.data_column.forEach((data_column_val) => {
                        for (const [key, val] of Object.entries(data_column_val)) {
                    
                            //console.log(val)
                            if(key == i){
                                let objColumnSub = {}; 
                                val.column.forEach((column) => {
                                    if(this.itemActive.includes(column.code)){
                                        objColumnSub[column.code] = true;
                                    }else{
                                        objColumnSub[column.code] = false;
                                    }
                                });        
                                objColumn[val.key] = objColumnSub;    
                            }                            
                        }                        
                        
                    });
                }


                //console.log(JSON.stringify(objColumn))
                if(this.type === "prix"){
                    this.$store.dispatch("advanced_result/prix_saveColumn", { column : objColumn, type : this.type} ).then(()=> {
                       this.modal.hide();
                    });    
                }else{
                    this.$store.dispatch("advanced_result/saveColumn", { column : objColumn, type : this.type} ).then(()=> {
                       this.modal.hide();
                    });    
                }
                this.canSave = false;
                //console.log('saveConfigColumn')
            }
        },
        checkCanSave(){
            let temp = {};
            let temp2 = {};
            this.original_column.forEach((data_column_val) => {
                data_column_val.forEach((val) => {
                    val.column.forEach((column) => {
                        temp[column.code] = column.Status;
                    });
                }); 
            });
             

            this.data_column.forEach((data_column_val) => {
                data_column_val.forEach((val) => {                 
                    val.column.forEach((column) => {
                        if(this.itemActive.includes(column.code)){
                            temp2[column.code] = true;
                        }else{
                            temp2[column.code] = false;
                        }
                    });        
                        
                });
            });

            // console.log(JSON.stringify(temp))
            // console.log(JSON.stringify(temp2))

            if(JSON.stringify(temp) !== JSON.stringify(temp2)){
                this.canSave = true
            }else{
                console.log('in false')
                this.canSave = false
            }
        },
        onEnd(){ 
            this.checkCanSave()            
            console.log('onEnd')
        },
        height(tag){
            return this.$refs[tag].clientHeight
        }
    },
    computed: {       
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    },
    watch: {            
        itemActive: function(val, before_val) {
            console.log('watch  --- itemActive' + val)
            if(before_val.length > 0){
                this.canSave = true;
            }
            
        }
    },
   
}
</script>

<style scoped>

.auto-width{
    flex: 0 0 25%;
    width: 350px;
    padding-right: 15px;
    padding-left: 15px;
}

@media(max-width: 991px){
    .auto-width{
        flex: 0 0 48%;
        width: 350px;
        padding-right: 15px;
        padding-left: 15px;
    } 
}

@media (max-width: 767px) {
     .auto-width{
        width: 100%;
    } 
}

.text-title-collapse {
    font-size: 14px;
    font-weight: bold;
    color: #ff6300; 
    display: inline-block;
    vertical-align: middle;
    width: 83%; 
    padding-left: 5px;
}
.bt-icon-down{
    color: #ff6300;
    float: right;
}
.box-border-gray-collapse {
    padding: 10px 15px;
    margin-bottom: 15px;
    margin-left: 15px;
    color: #212529;
    text-align: left;
    border: solid 1px #ddd;
    border-radius: 17px;
}
.main-dd{height: auto;}
*:focus {
    outline: 0;
}
.list-li-item {
    padding: 8px 8px 6px 8px !important;
    font-size: 13px;
    background: #fbfbff;
    border-bottom: none;
    margin-bottom: 8px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(187, 195, 208, 0.5);
}
.checkbox-list-li {
    list-style: none;
    padding: 0;
    border-bottom: none;
    margin-bottom: 7px;
}
.group-checkbox div{vertical-align: middle}
</style>