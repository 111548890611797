<template>
<div class="box-ui-icon">
    <div class="fa-pull-left">
        <div class="col-icon" @click="selectExport">
            <em class="icon-view-section"></em>
            <span>{{$t('View selection')}}</span>
        </div>
        <div class="col-icon"  @click="clearExport">
            <em class="icon-delete"></em>
            <span>{{$t('Delete selection')}}</span>
        </div>
        <div class="col-icon" data-bs-toggle="modal" data-bs-target="#modal-config-column">
            <em class="icon-organise-section"></em>
            {{$t('Show/Hide columns')}}
        </div>
        <div v-if="page == 'report'" class="col-icon" data-bs-toggle="modal" data-bs-target="#modal-filter-report">
            <em class="icon-organise-section"></em>
            {{$t('Filters')}}
        </div>
        <div v-if="$route.name == 'advanced_result_prix' || $route.name == 'advanced_result_rtu' || $route.name == 'advanced_result_prise_en_charge'|| $route.name == 'advanced_result_atu_postatu'|| $route.name == 'advanced_result_ceesp'|| $route.name == 'advanced_result_transparence_ceesp' || $route.name == 'advanced_result'" class="col-icon" data-bs-toggle="modal" data-bs-target="#modal-save-keyword">
            <div class="box-save-search2 m-0" >
                {{$t('Mémoriser la requête')}}                 
            </div>
        </div>
    </div>
    <div class="fa-pull-right">
        <div class="icon-right" v-if="data">
            <pagination :data="data" @pagination-change-page="getResults" :limit="3"></pagination>
        </div>
        <div class="icon-right" @click="fullScreen">
            <img src="@/assets/images/icon/ic-fullview.svg" alt="" />
        </div>
        
        <IconTopTableLegend />
       
        <div v-if="canExport" class="icon-right main-overlay load-excel-overlay" @click="OnExportExcel()" >
            <img src="@/assets/images/icon-download.svg" alt="" />
            <div class="overlay" v-if="load_excel">
            <div class="loadding_circle" ></div>
            </div>
        </div>       
    </div>
    <div class="clearfix"></div> 
</div>

  
 
</template>

<script>
import pagination from '@/components/elements/pagination.vue';
import IconTopTableLegend from '@/components/elements/icon_top_table_legend.vue'
export default {
  
    name: "icon_top_table",
    components: {      
        pagination,
        IconTopTableLegend

    },
    data() {
        return { 
            // exportExcel : false,
            inputMySearch : "",
        };
    },
    props: {
        request_data : Function, 
        page: {
            type: String,
            required: false,
            default: "",
        },
    },
    mounted() {
        // if(['rtu', 'atu_postatu', 'ceesp', 'transparence_ceesp', 'prise_en_charge', 'essais_clinique', 'transparence', 'prix'].includes(this.page)){
        //     this.exportExcel = true 
        // }
    },
    computed: {  
        data(){
            if(this.page == "prix"){
                return this.$store.getters['advanced_result/prix_data'] 
            }else if(this.page == "essais_clinique"){
                return this.$store.getters['essais_clinique/data']
            }else if(this.page == "report"){
                return this.$store.getters['report/data']
            }else{
                return this.$store.getters['advanced_result/data']
            }
        }, 
        load_excel(){
            return this.$store.getters['advanced_result/load_excel'] || this.$store.getters['essais_clinique/load_excel'] || this.$store.getters['report/load_excel']
        },
        type() {
            if(this.page == "essais_clinique"){
                return this.$store.getters['essais_clinique/type']
            }else if(this.page == "report"){
                return this.$store.getters['report/type']
            }else{
                return this.$store.getters['advanced_result/type']
            }            
        },
        canExport(){            
            return this.$store.getters["users/export"];
        }
    },
    methods: {
        getResults(page = 1) { 
            console.log(' page = '+page)
            this.$emit('onRequest', {page}) 
            document.querySelector('.content-table-results').scrollTop = 0           
        },
        selectExport(){      
            this.$emit('onSelectExport') 
        },
        clearExport(){                  
            this.$emit('onClearExport') 
        },
        OnExportExcel(){
            this.$emit('onRequest', {export : true}) 
        },
        fullScreen(){
            const routeData = this.$router.resolve({query: Object.assign({}, this.$route.query, { zoom : true }) });
            window.open(routeData.href, '_blank');        
        },
    },
};
</script>

<style scoped>


</style>