<template>
    <div>        
        <div class="body-w100" v-if="data.data">
            <div class="box-detail">
                <!-- <div class="wrapper-box-hta">
                    <h1 class="fa-pull-left mr-4 mt-1">Reimbursement Status</h1>
                    <div class="clearfix"></div>
                </div>  -->
                <ConfigColumnAdvance :type="type" :column="column" :reset_column="reset_column" />
                <ModalSaveKeyword :type="type" />
                <IconTopTable @onRequest="request_data" :page="page" @onSelectExport="selectExport" @onClearExport="clearExport" />
                <div class="box-advanced-option "> 
                    <div class="box-check box-check-sm box-inline bg_highlight1">
                        <label> 
                            <input type="checkbox" v-model="options.ss_coll" name="ss_coll" @change="changeOptions">
                            <span class="check-recherche"></span>
                            <div class="txt-check">
                                {{ $t("show_ss_coll") }} 
                            </div>
                        </label>
                    </div>
                    <div class="box-check box-check-sm box-inline bg_highlight2">
                        <label> 
                            <input type="checkbox" v-model="options.parallel" name="parallel" @change="changeOptions">
                            <span class="check-recherche"></span>
                            <div class="txt-check">
                                {{ $t("show_parallel") }} 
                            </div>
                        </label>
                    </div>
                    <div class="box-check box-check-sm box-inline bg_highlight3">
                        <label> 
                            <input type="checkbox" v-model="options.amm" name="amm" @change="changeOptions">
                            <span class="check-recherche"></span>
                            <div class="txt-check">
                                {{ $t("show_amm") }} 
                            </div>
                        </label>
                    </div>
                    <div class="box-check box-check-sm box-inline bg_highlight4">
                        <label> 
                            <input type="checkbox" v-model="options.latest_price" name="latest_price" @change="changeOptions">
                            <span class="check-recherche"></span>
                            <div class="txt-check">
                                {{ $t("show_lastest_price") }} 
                            </div>
                        </label>
                    </div>
                    <div class="box-check box-check-sm box-inline bg_highlight5">
                        <label> 
                            <input type="checkbox" v-model="options.generique" name="generique" @change="changeOptions">
                            <span class="check-recherche"></span>
                            <div class="txt-check">
                                {{ $t("show_status_generique") }} 
                            </div>
                        </label>
                    </div>
                
                </div>

                <div class="content-table-results mt-2">
                    <table class="table table-fixed table-stripes" :id="actionName" >
                        <ColumnColGroup :page="page" :column="orderColumn" :default_column="default_column"/>
                        <ColumnThead :page="page" :main_page="main_page" :column="orderColumn" :default_column="default_column"/>  
                        <tbody> 
                            <template v-if="data.data && data.data.length > 0">
                            <template v-for="(item, key) in data.data" >
                            <tr v-for="(sub_item, sub_key) in max_row(item)" :key="key+'_'+sub_key" :class="odd_even_border(key, sub_key)+colorRow(item)" >                
                                
                                <td>
                                    <template v-if="sub_key===0">
                                        <label class="table-check">
                                            <input type="checkbox"  v-model="select_export" :value="item.bp_id">
                                            <span class="checkbox-table"></span>
                                        </label>
                                    </template>
                                </td>
                                <td>
                                    <template v-if="sub_key===0">
                                    <span v-html="icon_link(item.bp_id,'bp')"></span>
                                    <div v-if="isAdmin">
                                        <a :href="modifyPage('boite_presentations_v2', item.bp_id)" target="_blank" rel="noopener"> 
                                            <em class="icon-edit"></em>
                                        </a>
                                    </div>
                                    </template>
                                </td>
                                <template v-for="(column) in orderColumn" :key="column.code" >                                               
                                    <td v-if="column.Status && column.code == 'general_001'" :code="column.code">                                                 
                                        <template v-if="sub_key===0">{{ check_empty(item.bp_cip7)}}</template>                                                   
                                    </td>      
                                        <td v-else-if="column.Status && column.code == 'general_002'" :code="column.code">
                                        <span v-if="sub_key===0" v-html="cip13(item)"></span>
                                    </td>                                                
                                    <td v-else-if="column.Status && column.code == 'general_003'" :code="column.code">
                                        <template v-if="sub_key===0">{{ check_empty(item.bp_ucd) }}</template>
                                    </td> 
                                    <td v-else-if="column.Status && column.code == 'general_004'" :code="column.code">
                                        <template v-if="sub_key===0">{{ check_empty(item.bp_ucd13) }}</template>                                                    
                                    </td> 
                                    <td v-else-if="column.Status && column.code == 'general_005'" :code="column.code">
                                        <template v-if="sub_key===0">{{ check_empty(item.bp_cis)}}</template>                                                   
                                    </td> 
                                    <td v-else-if="column.Status && column.code == 'general_006'" :code="column.code">
                                        <template v-if="sub_key===0">{{ check_empty(item.bp_index_cis)}}</template>                                                                                               
                                    </td> 
                                    <td v-else-if="column.Status && column.code == 'general_007'" :code="column.code">
                                        <template v-if="sub_key===0">{{ check_empty(item.bp_atc_pro)}}</template>
                                    </td>   
                                        <td v-else-if="column.Status && column.code == 'general_008'" :code="column.code">
                                        <template v-if="sub_key===0">{{ check_empty(item.bp_exploitant)}}</template>
                                    </td>    
                                    <td v-else-if="column.Status && column.code == 'general_009'" :code="column.code">
                                        <template v-if=" item.boite_presentation_prix_atu && item.boite_presentation_prix_atu[sub_key] && item.boite_presentation_prix_atu[sub_key]['prix_atu']" >
                                            {{ format_prix(item.boite_presentation_prix_atu[sub_key]['prix_atu'], 3) }}
                                        </template>
                                        <span v-else> - </span>
                                    </td>    
                                    <td v-else-if="column.Status && column.code == 'general_010'" :code="column.code">
                                        <template v-if="sub_key===0">{{ check_empty(item.bp_unite)}}</template>                                                 
                                    </td>    
                                    <td v-else-if="column.Status && column.code == 'general_011'" :code="column.code">                                                   
                                        <template v-if="item.boite_presentation_forme_galenique_lists[sub_key] && item.boite_presentation_forme_galenique_lists[sub_key]['text']">
                                            {{ check_empty_with_locale(item.boite_presentation_forme_galenique_lists[sub_key]['text']) }}
                                        </template>             
                                        <span v-else> - </span>                                  
                                    </td>    
                                    <td v-else-if="column.Status && column.code == 'general_012'" :code="column.code">
                                        <template v-if="sub_key===0">{{ item.conditionnement_trimestriel ? $t('yes') : "-" }}</template>  
                                    </td>    
                                    <td v-else-if="column.Status && column.code == 'general_013'" :code="column.code">
                                        <template v-if="sub_key===0">{{ check_empty_with_locale(item.bp_type_amm, 'boite.type_amm.')}}</template>                                                    
                                    </td> 
                                    <td v-else-if="column.Status && column.code == 'general_014'" :code="column.code">
                                        <template v-if="sub_key===0">{{ item.bp_parallel_import ? $t('yes') : "-" }}</template>   
                                    </td> 
                                    <td v-else-if="column.Status && column.code == 'general_015'" :code="column.code">                                                 
                                        <template  v-if="sub_key===0">
                                            <span v-if="$i18n.locale === 'en'">{{ check_empty(item.bp_presentation_en)}}</span>
                                            <span v-else-if="$i18n.locale === 'de'">{{ check_empty(item.bp_presentation_de)}}</span> 
                                            <span v-else >{{ check_empty(item.bp_presentation)}}</span>                            
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'general_016'" :code="column.code">                                                 
                                        <template v-if="sub_key===0" >{{ check_empty(item.bp_dci)}}</template>   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ss_001'" :code="column.code">                                                                                                     
                                        <template v-if="sub_key===0">
                                            {{dataPrixVille[item.bp_id] && dataPrixVille[item.bp_id][sub_key]  ? check_empty(DDMMMYY(dataPrixVille[item.bp_id][sub_key]['prix_date_ins_ss'])) : "-"}}
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ss_002'" :code="column.code">                                                                                                     
                                        {{ dataPrixVille[item.bp_id] && dataPrixVille[item.bp_id][sub_key] ? check_empty(DDMMMYY(dataPrixVille[item.bp_id][sub_key]['prix_date_rad_ss'])) : "-"}}
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ss_003'" :code="column.code">                                                 
                                        <template  v-if="sub_key===0">{{ check_empty(item.delay_obtention_ss)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ss_004'" :code="column.code">      
                                        <template v-if="dataPrixVille[item.bp_id] && dataPrixVille[item.bp_id][sub_key] && dataPrixVille[item.bp_id][sub_key]['prix_ht_ss']">
                                            <template v-if="options.latest_price">                                                                                                                                                         
                                                {{  format_french_number(dataPrixVille[item.bp_id][sub_key]['prix_ht_ss']) }}
                                            </template>
                                            <template v-else-if="sub_key===0">
                                                {{  format_french_number(dataPrixVille[item.bp_id][sub_key]['prix_ht_ss']) }}
                                            </template>
                                        </template>
                                        <span v-else> - </span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ss_005'" :code="column.code">   
                                        <template v-if="dataPrixVille[item.bp_id] && dataPrixVille[item.bp_id][sub_key] && dataPrixVille[item.bp_id][sub_key]['prix_ttc_ss']">
                                            <template v-if="options.latest_price">                                                                                                   
                                                {{ format_french_number(dataPrixVille[item.bp_id][sub_key]['prix_ttc_ss']) }}
                                            </template>
                                            <template v-else-if="sub_key===0">
                                                {{   format_french_number(dataPrixVille[item.bp_id][sub_key]['prix_ttc_ss'])  }}
                                            </template>
                                        </template>
                                        <span v-else> - </span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ss_006'" :code="column.code">                                                 
                                        <template v-if="dataPrixVille[item.bp_id] && dataPrixVille[item.bp_id][sub_key] && dataPrixVille[item.bp_id][sub_key]['prix_date_prix_ss']">                                                    
                                            <template v-if="options.latest_price">                                                                                                   
                                                {{  check_empty(DDMMMYY(dataPrixVille[item.bp_id][sub_key]['prix_date_prix_ss'])) }}
                                            </template>
                                            <template v-else-if="sub_key===0">                                                             
                                                {{  check_empty(DDMMMYY(dataPrixVille[item.bp_id][sub_key]['prix_date_prix_ss'])) }}
                                            </template>
                                        </template>
                                        <span v-else> - </span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ss_007'" :code="column.code">    
                                        <template v-if="dataPrixVille[item.bp_id] && dataPrixVille[item.bp_id][sub_key] && dataPrixVille[item.bp_id][sub_key]['prix_date_application_ville']">                                                                                                        
                                            <template v-if="options.latest_price">                                                                                                   
                                                {{ check_empty(DDMMMYY(dataPrixVille[item.bp_id][sub_key]['prix_date_application_ville'])) }}
                                            </template>
                                            <template v-else-if="sub_key===0"> 
                                                    {{ check_empty(DDMMMYY(dataPrixVille[item.bp_id][sub_key]['prix_date_application_ville'])) }}
                                            </template>                        
                                        </template>  
                                        <span v-else> - </span>                      
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ss_008'" :code="column.code">       
                                        <template v-if="dataPrixVille[item.bp_id] && dataPrixVille[item.bp_id][sub_key] && dataPrixVille[item.bp_id][sub_key]['prix_variation_ss']">                                                                                                                                                            
                                            <template v-if="options.latest_price">                                                                                                   
                                                {{ format_french_number(dataPrixVille[item.bp_id][sub_key]['prix_variation_ss'])  }}
                                            </template>
                                            <template v-else-if="sub_key===0"> 
                                                    {{ dataPrixVille[item.bp_id][sub_key]['prix_variation_ss'] > -1 ? format_french_number(dataPrixVille[item.bp_id][sub_key]['prix_variation_ss']) :'-' }}
                                                </template>                        
                                        </template>   
                                        <span v-else> - </span>                                      
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ss_009'" :code="column.code">                                                 
                                        <template v-if="sub_key===0"> 
                                            {{ dataPrixVille[item.bp_id] && dataPrixVille[item.bp_id][sub_key] ? check_empty(dataPrixVille[item.bp_id][sub_key]['prix_tx_remb_uncam']) : "-" }}
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'commu_001'" :code="column.code">   
                                        <template v-if="sub_key===0">                                                    
                                            {{ dataPrixVille[item.bp_id] && dataPrixVille[item.bp_id][sub_key] ? check_empty(DDMMMYY(dataPrixVille[item.bp_id][sub_key]['prix_date_ins_coll'])) :  "-"  }}                                                        
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'commu_002'" :code="column.code">    
                                        <template v-if="sub_key===0">                                                                         
                                            {{ dataPrixVille[item.bp_id] && dataPrixVille[item.bp_id][sub_key] ? check_empty(DDMMMYY(dataPrixVille[item.bp_id][sub_key]['prix_date_rad_coll'])) :"-" }}                                                        
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 't2a_001'" :code="column.code">    
                                        <template v-if="sub_key===0">                                                      
                                            {{ item.prix_evolution_t2a && item.prix_evolution_t2a[sub_key] ? check_empty(DDMMMYY(item.prix_evolution_t2a[sub_key]['prix_date_ins_t2a'])) : "-" }}                                                           
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 't2a_002'" :code="column.code">                                                     
                                        {{ item.prix_evolution_t2a && item.prix_evolution_t2a[sub_key] ? check_empty(DDMMMYY(item.prix_evolution_t2a[sub_key]['prix_date_rad_t2a'])) : "-"  }}                                                           
                                    </td>
                                    <td v-else-if="column.Status && column.code == 't2a_003'" :code="column.code">  
                                        <template v-if="sub_key===0">{{ check_empty(item.delay_ins_t2a)}}</template> 
                                        </td>
                                    <td v-else-if="column.Status && column.code == 't2a_004'" :code="column.code">  
                                        <template v-if="sub_key===0">{{ check_empty(item.delay_obtention_t2a)}}</template> 
                                    </td> 
                                    <td v-else-if="column.Status && column.code == 't2a_005'" :code="column.code">   
                                        <template v-if=" item.prix_evolution_t2a && item.prix_evolution_t2a[sub_key] && item.prix_evolution_t2a[sub_key]['prix_t2a'] " >
                                            <template v-if="options.latest_price">                                                                                             
                                                {{  format_prix(item.prix_evolution_t2a[sub_key]['prix_t2a'], 3)  }}
                                            </template>
                                            <template v-else-if="sub_key===0"> 
                                                    {{ item.prix_evolution_t2a[sub_key]['prix_t2a'] > -1 ? format_prix(item.prix_evolution_t2a[sub_key]['prix_t2a'], 3) : '-' }}
                                            </template>
                                        </template>          
                                        <span v-else> - </span>                                          
                                    </td>
                                    <td v-else-if="column.Status && column.code == 't2a_006'" :code="column.code">  
                                        <template v-if=" item.prix_evolution_t2a && item.prix_evolution_t2a[sub_key] && item.prix_evolution_t2a[sub_key]['prix_date_prix_t2a'] " >
                                            <template v-if="options.latest_price">                                                                                             
                                                {{ check_empty(DDMMMYY(item.prix_evolution_t2a[sub_key]['prix_date_prix_t2a'])) }}
                                            </template>
                                            <template v-else-if="sub_key===0"> 
                                                {{ check_empty(DDMMMYY(item.prix_evolution_t2a[sub_key]['prix_date_prix_t2a'])) }}
                                            </template>
                                        </template>         
                                        <span v-else> - </span>   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 't2a_007'" :code="column.code"> 
                                        <template v-if=" item.prix_evolution_t2a && item.prix_evolution_t2a[sub_key] && item.prix_evolution_t2a[sub_key]['prix_date_application_t2a'] " >
                                            <template v-if="options.latest_price">                                                                                             
                                                {{ check_empty(DDMMMYY(item.prix_evolution_t2a[sub_key]['prix_date_application_t2a'])) }}
                                            </template>
                                            <template v-else-if="sub_key===0"> 
                                                {{ check_empty(DDMMMYY(item.prix_evolution_t2a[sub_key]['prix_date_application_t2a'])) }}
                                            </template>
                                        </template>     
                                        <span v-else> - </span>                                  
                                    </td>
                                    <td v-else-if="column.Status && column.code == 't2a_008'" :code="column.code">  
                                        <template v-if=" item.prix_evolution_t2a && item.prix_evolution_t2a[sub_key] && item.prix_evolution_t2a[sub_key]['prix_variation_t2a'] " >
                                            <template v-if="options.latest_price">                                                                                             
                                                {{ format_french_number(item.prix_evolution_t2a[sub_key]['prix_variation_t2a'])  }}
                                            </template>
                                            <template v-else-if="sub_key===0"> 
                                                {{ format_french_number(item.prix_evolution_t2a[sub_key]['prix_variation_t2a']) }}
                                            </template>
                                        </template> 
                                        <span v-else> - </span>      
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'retro_001'" :code="column.code"> 
                                        <template v-if="sub_key===0">
                                            {{ item.prix_evolution_retro && item.prix_evolution_retro[sub_key]  ? check_empty(DDMMMYY(item.prix_evolution_retro[sub_key]['prix_date_ins_retro'])) : "-"}}
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'retro_002'" :code="column.code">  
                                        <template v-if="sub_key===0">
                                            {{ item.prix_evolution_retro && item.prix_evolution_retro[sub_key] ? check_empty(DDMMMYY(item.prix_evolution_retro[sub_key]['prix_date_rad_retro'])) : "-"}}
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'retro_003'" :code="column.code">  
                                        <template v-if="sub_key===0">
                                            {{ check_empty(item.delay_obtention_retro) }}
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'retro_004'" :code="column.code">   
                                        <template v-if=" item.prix_evolution_retro && item.prix_evolution_retro[sub_key] && item.prix_evolution_retro[sub_key]['prix_retro_ht'] " >
                                            <template v-if="options.latest_price">                                                                                             
                                                {{ format_prix(item.prix_evolution_retro[sub_key]['prix_retro_ht'], 3)  }} 
                                            </template>
                                            <template v-else-if="sub_key===0"> 
                                                {{ format_prix(item.prix_evolution_retro[sub_key]['prix_retro_ht'], 3) }}
                                            </template>
                                        </template>    
                                        <span v-else> - </span>   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'retro_005'" :code="column.code">  
                                        <template v-if=" item.prix_evolution_retro && item.prix_evolution_retro[sub_key] && item.prix_evolution_retro[sub_key]['prix_date_prix_retro'] " >
                                            <template v-if="options.latest_price">                                                                                             
                                                {{ check_empty(DDMMMYY(item.prix_evolution_retro[sub_key]['prix_date_prix_retro'])) }} 
                                            </template>
                                            <template v-else-if="sub_key===0"> 
                                                {{ check_empty(DDMMMYY(item.prix_evolution_retro[sub_key]['prix_date_prix_retro']))  }}
                                            </template>
                                        </template>  
                                        <span v-else> - </span>   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'retro_006'" :code="column.code">       
                                        <template v-if=" item.prix_evolution_retro && item.prix_evolution_retro[sub_key] && item.prix_evolution_retro[sub_key]['prix_date_application_retro'] " >
                                            <template v-if="options.latest_price">                                                                                             
                                                {{ check_empty(DDMMMYY(item.prix_evolution_retro[sub_key]['prix_date_application_retro'])) }} 
                                            </template>
                                            <template v-else-if="sub_key===0"> 
                                                {{ check_empty(DDMMMYY(item.prix_evolution_retro[sub_key]['prix_date_application_retro']))  }}
                                            </template>
                                        </template> 
                                        <span v-else> - </span>   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'retro_007'" :code="column.code">      
                                        <template v-if=" item.prix_evolution_retro && item.prix_evolution_retro[sub_key] && item.prix_evolution_retro[sub_key]['prix_variation_retro'] " >
                                            <template v-if="options.latest_price">                                                                                             
                                                {{ format_french_number(item.prix_evolution_retro[sub_key]['prix_variation_retro'])  }} 
                                            </template>
                                            <template v-else-if="sub_key===0"> 
                                                {{ format_french_number(item.prix_evolution_retro[sub_key]['prix_variation_retro']) }}
                                            </template>
                                        </template>    
                                        <span v-else> - </span>                                              
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'generique_001'" :code="column.code">     
                                        <template v-if="sub_key===0">
                                            {{ check_empty_with_locale(item.bp_statut_generique, 'boite.status_generique.')}}
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'generique_002'" :code="column.code">   
                                        <template v-if="item.ansm && item.ansm[sub_key]">
                                            {{ check_empty(item.ansm[sub_key]['ansm_id']) }} 
                                        </template>
                                        <span v-else> - </span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'generique_003'" :code="column.code"> 
                                        <template v-if="item.ansm && item.ansm[sub_key]">
                                            {{ check_empty(item.ansm[sub_key]['libelle'])}}                                               
                                        </template>
                                        <span v-else> - </span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'generique_004'" :code="column.code">     
                                        <template v-if="item.ansm && item.ansm[sub_key]">
                                            {{ check_empty(item.ansm[sub_key]['libelle_dci'])}}                                                                                                   
                                        </template>
                                        <span v-else> - </span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'generique_005'" :code="column.code">             
                                        <template v-if="item.ansm && item.ansm[sub_key]">
                                            {{ check_empty(DDMMMYY(item.ansm[sub_key]['date_create'])) }}
                                        </template>
                                        <span v-else> - </span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'generique_006'" :code="column.code">      
                                        <template v-if="item.ansm && item.ansm[sub_key]">
                                            {{ check_empty(DDMMMYY(item.ansm[sub_key]['date_fin'])) }}                                           
                                        </template>
                                        <span v-else> - </span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'biosimilaire_001'" :code="column.code">                                                       
                                        <template v-if="item.biosimilaire && item.biosimilaire[sub_key]">
                                            {{ check_empty(item.biosimilaire[sub_key]['ansm_biosimilaire_id'])}}
                                        </template>
                                        <span v-else> - </span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'biosimilaire_002'" :code="column.code">                                                               
                                        <template v-if="item.biosimilaire && item.biosimilaire[sub_key]">
                                            {{ check_empty(item.biosimilaire[sub_key]['medicament_reference'])}}
                                        </template>  
                                        <span v-else> - </span>                                    
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'biosimilaire_003'" :code="column.code">     
                                        <template v-if="item.biosimilaire && item.biosimilaire[sub_key]">
                                            {{ check_empty(item.biosimilaire[sub_key]['substance_active'])}}                                                       
                                        </template>   
                                        <span v-else> - </span>                                       
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'biosimilaire_004'" :code="column.code">         
                                        <template v-if="item.biosimilaire && item.biosimilaire[sub_key]">
                                            {{ check_empty(DDMMMYY(item.biosimilaire[sub_key]['date_create_group'])) }}
                                        </template>  
                                        <span v-else> - </span>                                     
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'biosimilaire_005'" :code="column.code">                                                          
                                        <template v-if="item.biosimilaire && item.biosimilaire[sub_key]">
                                            {{ check_empty(DDMMMYY(item.biosimilaire[sub_key]['date_fin_group'])) }}
                                        </template>  
                                        <span v-else> - </span>                                       
                                    </td>  

                                    <!-- <td v-else-if="column.Status && column.code == 'transparence_001'" :code="column.code">      
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['trs_labo']">
                                            {{dataTransparence[item.bp_id][sub_key]['trs_labo']}} 
                                        </template>  
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_002'" :code="column.code">        
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['transparence_atc']">
                                            {{dataTransparence[item.bp_id][sub_key]['transparence_atc']['trsa_atc']}}
                                        </template>  
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_003'" :code="column.code">                                                                                                               
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['trs_date']">
                                            {{ DDMMMYY(dataTransparence[item.bp_id][sub_key]['trs_date'])}}
                                        </template>  
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_004'" :code="column.code">                                                   
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['trs_date_type_amm']">
                                            {{ DDMMMYY(dataTransparence[item.bp_id][sub_key]['trs_date_type_amm'])}}
                                        </template>
                                        <template v-else-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['nd']">
                                            {{ $t('Not available') }}
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_005'" :code="column.code">                                                                                                                      
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['trs_type_amm']">
                                            {{dataTransparence[item.bp_id][sub_key]['trs_type_amm']}}
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_006'" :code="column.code">       
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['amm_ct']">
                                            {{dataTransparence[item.bp_id][sub_key]['amm_ct']}}
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_007'" :code="column.code">                                                                                                                 
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['trs_type_demandes']">
                                            {{dataTransparence[item.bp_id][sub_key]['trs_type_demandes']}}
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_008'" :code="column.code">                                                             
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['trs_motif']">
                                            {{dataTransparence[item.bp_id][sub_key]['trs_motif']}}
                                        </template>                                     
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_009'" :code="column.code">                                                                                                                                  
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['trs_indication']">
                                            <span v-if="$i18n.locale === 'fr'"><Readmore :longText="dataTransparence[item.bp_id][sub_key]['trs_indication']"/></span>
                                            <span v-else ><Readmore :longText="dataTransparence[item.bp_id][sub_key]['trs_indication_en']"/> </span>                            
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_010'" :code="column.code">                                                        
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['transparence_smr'] && dataTransparence[item.bp_id][sub_key]['transparence_smr']['smr_type']">
                                            <div v-html="smr_type(dataTransparence[item.bp_id][sub_key]['transparence_smr']['smr_type'])"></div>
                                        </template>                                       
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_011'" :code="column.code">   
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['transparence_smr'] && dataTransparence[item.bp_id][sub_key]['transparence_smr']['smr_title']">
                                            <template v-if="$i18n.locale === 'fr'">{{ dataTransparence[item.bp_id][sub_key]['transparence_smr']['smr_title'] }} </template>
                                            <template v-else >{{ dataTransparence[item.bp_id][sub_key]['transparence_smr']['smr_title_en'] }}</template>                            
                                        </template> 
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_012'" :code="column.code">   
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['transparence_smr'] && dataTransparence[item.bp_id][sub_key]['transparence_smr']['smr_text']">
                                            <template v-if="$i18n.locale === 'fr'"><Readmore :longText="dataTransparence[item.bp_id][sub_key]['transparence_smr']['smr_text']"/></template>
                                            <template v-else><Readmore :longText="dataTransparence[item.bp_id][sub_key]['transparence_smr']['smr_text_en']"/></template>                            
                                        </template>                                       
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_013'" :code="column.code">   
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['transparence_smr'] && dataTransparence[item.bp_id][sub_key]['transparence_smr']['asmr_obtenue']">                                                    
                                            <div v-html="$t('transparence.asmr.'+dataTransparence[item.bp_id][sub_key]['transparence_smr']['asmr_obtenue'])"></div>                                                   
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_014'" :code="column.code">                                                 
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['transparence_smr'] && dataTransparence[item.bp_id][sub_key]['transparence_smr']['asmr_title']">
                                            <template v-if="$i18n.locale === 'fr'">{{ dataTransparence[item.bp_id][sub_key]['transparence_smr']['asmr_title'] }} </template>
                                            <template v-else>{{ dataTransparence[item.bp_id][sub_key]['transparence_smr']['asmr_title_en'] }}</template>                            
                                        </template> 
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_015'" :code="column.code">   
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['transparence_smr'] && dataTransparence[item.bp_id][sub_key]['transparence_smr']['asmr_text']">
                                            <template v-if="$i18n.locale === 'fr'"><Readmore :longText="dataTransparence[item.bp_id][sub_key]['transparence_smr']['asmr_text']"/></template>
                                            <template v-else><Readmore :longText="dataTransparence[item.bp_id][sub_key]['transparence_smr']['asmr_text_en']"/></template>                            
                                        </template>                                        
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_016'" :code="column.code">     
                                            <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['transparence_smr'] && dataTransparence[item.bp_id][sub_key]['transparence_smr']['isp_json']">
                                            <span v-html="json_fields(dataTransparence[item.bp_id][sub_key]['transparence_smr']['isp_json'], 'ISP_libelle')"></span>    
                                        </template>                                            
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_017'" :code="column.code">     
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['transparence_smr'] && dataTransparence[item.bp_id][sub_key]['transparence_smr']['isp_json']">
                                            <template v-if="$i18n.locale === 'fr'"><Readmore :longText="json_fields(dataTransparence[item.bp_id][sub_key]['transparence_smr']['isp_json'], 'ISP_text')"/></template>
                                            <template v-else><Readmore :longText="json_fields(dataTransparence[item.bp_id][sub_key]['transparence_smr']['isp_json'], 'ISP_text_en')"/></template>
                                        </template>                                            
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_018'" :code="column.code">  
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['audition'] && dataTransparence[item.bp_id][sub_key]['audition']['audition_a_cocher']">
                                            {{ dataTransparence[item.bp_id][sub_key]['audition']['audition_a_cocher'] }}
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_019'" :code="column.code">                                                      
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['audition']">    
                                            <Readmore v-if="$i18n.locale === 'fr'" :longText="dataTransparence[item.bp_id][sub_key]['audition']['audition_text']"/>                                                         
                                            <Readmore v-else :longText="dataTransparence[item.bp_id][sub_key]['audition']['audition_text_en']"/>                                                          
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_020'" :code="column.code">        
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['trs_modify_smr_asmr']">                                                       
                                            {{ dataTransparence[item.bp_id][sub_key]['trs_modify_smr_asmr'] ? $t('Yes') : "-" }}
                                        </template>                                        
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_021'" :code="column.code">      
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && (dataTransparence[item.bp_id][sub_key]['trs_population_cible'] || dataTransparence[item.bp_id][sub_key]['trs_population_cible_en'] ) ">    
                                            <Readmore v-if="$i18n.locale === 'fr'" :longText="dataTransparence[item.bp_id][sub_key]['trs_population_cible']"/>                                                         
                                            <Readmore v-else :longText="dataTransparence[item.bp_id][sub_key]['trs_population_cible_en']"/>                                                          
                                        </template> 
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_022'" :code="column.code">                                                 
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['transparence_smr'] && dataTransparence[item.bp_id][sub_key]['transparence_smr']['nombre_de_patient_json']">
                                            <template v-if="$i18n.locale === 'fr'"><Readmore :longText="json_fields(dataTransparence[item.bp_id][sub_key]['transparence_smr']['nombre_de_patient_json'], 'NDP_text')"/></template>
                                            <template v-else><Readmore :longText="json_fields(dataTransparence[item.bp_id][sub_key]['transparence_smr']['nombre_de_patient_json'], 'NDP_text_en')"/></template>
                                        </template>                                                    
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_023'" :code="column.code">                                                      
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['trs_epi']">                                                       
                                            {{dataTransparence[item.bp_id][sub_key]['trs_epi'] === 1 ? $t('Yes') : ""}}
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_024'" :code="column.code">    
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['trs_mk_exception']">                                                       
                                            {{dataTransparence[item.bp_id][sub_key]['trs_mk_exception'] === 1 ? $t('Yes') : ""}}
                                        </template>      
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_025'" :code="column.code">                                                                                                                       
                                            <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['trs_mk_orphelin']">                                                       
                                            {{ $t("boite.trs_orphan_drug."+dataTransparence[item.bp_id][sub_key]['trs_mk_orphelin']) }}
                                        </template> 
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'transparence_026'" :code="column.code">                                                                                                         
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['trs_particularite']">                                                       
                                            {{ $t("boite.trs_classification."+ dataTransparence[item.bp_id][sub_key]['trs_particularite']) }}
                                        </template> 
                                    </td>  -->
                                    
                                    <!-- <td v-else-if="column.Status && column.code == 'ceesp_001'" :code="column.code">
                                        {{dataCeesp[item.bp_id]  && dataCeesp[item.bp_id][sub_key] && dataCeesp[item.bp_id][sub_key]['impact_significatif'] ? $t('yes_no_'+dataCeesp[item.bp_id][sub_key]['impact_significatif']) : ""}}
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_002'" :code="column.code">                                                                              
                                        {{dataCeesp[item.bp_id]  && dataCeesp[item.bp_id][sub_key] && dataCeesp[item.bp_id][sub_key]['conclusion'] ? $t('conclusion_'+ dataCeesp[item.bp_id][sub_key]['conclusion']) : ""}}
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_003'" :code="column.code">     
                                        {{dataCeesp[item.bp_id]  && dataCeesp[item.bp_id][sub_key] && dataCeesp[item.bp_id][sub_key]['ceesp_titleappreserve'] && dataCeesp[item.bp_id][sub_key]['ceesp_titleappreserve']['appreciation'] ? $t("appreciation."+dataCeesp[item.bp_id][sub_key]['ceesp_titleappreserve']['appreciation']) : ""}}
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_004'" :code="column.code">  
                                        <template v-if="$i18n.locale === 'fr'"><Readmore :longText="dataCeesp[item.bp_id]  && dataCeesp[item.bp_id][sub_key] && dataCeesp[item.bp_id][sub_key]['ceesp_titleappreserve'] && dataCeesp[item.bp_id][sub_key]['ceesp_titleappreserve']['reversefr'] ? dataCeesp[item.bp_id][sub_key]['ceesp_titleappreserve']['reversefr'] : '' "/></template>
                                        <template v-else><Readmore :longText="dataCeesp[item.bp_id]  && dataCeesp[item.bp_id][sub_key] && dataCeesp[item.bp_id][sub_key] && dataCeesp[item.bp_id][sub_key]['ceesp_titleappreserve'] && dataCeesp[item.bp_id][sub_key]['ceesp_titleappreserve']['reverseen'] ? dataCeesp[item.bp_id][sub_key]['ceesp_titleappreserve']['reverseen'] : '' "/></template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_005'" :code="column.code">
                                        {{dataCeesp[item.bp_id]  && dataCeesp[item.bp_id][sub_key] && dataCeesp[item.bp_id][sub_key]['additional_data'] ? $t("boite.yes_no."+dataCeesp[item.bp_id][sub_key]['additional_data']) :""}}
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_006'" :code="column.code">                          
                                        {{dataCeesp[item.bp_id]  && dataCeesp[item.bp_id][sub_key] && dataCeesp[item.bp_id][sub_key]['budget_impact'] ? $t("boite.yes_no."+dataCeesp[item.bp_id][sub_key]['budget_impact']) :""}}
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_007'" :code="column.code">
                                        <template v-if="dataCeesp[item.bp_id]  && dataCeesp[item.bp_id][sub_key] && dataCeesp[item.bp_id][sub_key]['evaluation_economic'] && dataCeesp[item.bp_id][sub_key]['evaluation_economic']['evaluation_economic_economic_analysis']">
                                            <p v-for="(evaluation_economic_economic_analysis, kk)  in dataCeesp[item.bp_id][sub_key]['evaluation_economic']['evaluation_economic_economic_analysis']" :key="column.code+'-'+kk" >     
                                                <template v-if="$i18n.locale === 'fr'">{{evaluation_economic_economic_analysis.comparator}}</template> 
                                                <template v-else>{{evaluation_economic_economic_analysis.comparator_en}}</template>  
                                            </p>
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'ceesp_008'" :code="column.code">  
                                        <template v-if="dataCeesp[item.bp_id]  && dataCeesp[item.bp_id][sub_key] && dataCeesp[item.bp_id][sub_key]['evaluation_economic'] && dataCeesp[item.bp_id][sub_key]['evaluation_economic']['evaluation_economic_economic_analysis']">
                                            <p v-for="(evaluation_economic_economic_analysis, kk)  in dataCeesp[item.bp_id][sub_key]['evaluation_economic']['evaluation_economic_economic_analysis']" :key="column.code+'-'+kk" >     
                                                <template v-if="dataCeesp[item.bp_id][sub_key]['evaluation_economic']['type_of_economic_analysis'] === 'cua'">
                                                    {{evaluation_economic_economic_analysis.icur_gbp + " " + $t('per QALY gained')}}
                                                </template> 
                                                <template v-else-if="dataCeesp[item.bp_id][sub_key]['evaluation_economic']['type_of_economic_analysis'] === 'cea'">
                                                    {{evaluation_economic_economic_analysis.icer_gbp + " " + evaluation_economic_economic_analysis.icer_type }}
                                                </template>
                                                </p>
                                        </template>                           
                                    </td>   -->

                                     

                                    <td v-else-if="column.Status && column.code == 'tfr_001'" :code="column.code">                                                 
                                        {{dataTfr[item.bp_id] && dataTfr[item.bp_id][sub_key]  ? check_empty(dataTfr[item.bp_id][sub_key]['id_tfr']) : "-"}}
                                    </td> 
                                    <td v-else-if="column.Status && column.code == 'tfr_002'" :code="column.code"> 
                                        {{dataTfr[item.bp_id] && dataTfr[item.bp_id][sub_key] ? check_empty_with_locale(dataTfr[item.bp_id][sub_key]['chk_tfr'], "boite.2yes_no.") : "-"}}
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'tfr_003'" :code="column.code">   
                                        {{dataTfr[item.bp_id] && dataTfr[item.bp_id][sub_key] ? check_empty(dataTfr[item.bp_id][sub_key]['tarif_ppttc']) : "-"}}
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'tfr_004'" :code="column.code"> 
                                        {{dataTfr[item.bp_id] && dataTfr[item.bp_id][sub_key] ? check_empty(DDMMMYY(dataTfr[item.bp_id][sub_key]['date_application'])) : "-"}}
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'tfr_005'" :code="column.code">                                                 
                                        {{dataTfr[item.bp_id] && dataTfr[item.bp_id][sub_key] ? check_empty(DDMMMYY(dataTfr[item.bp_id][sub_key]['date_jo'])) : "-"}} 
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'tfr_006'" :code="column.code"> 
                                        {{dataTfr[item.bp_id] && dataTfrRestCharge[item.bp_id] && dataTfr[item.bp_id][sub_key] ? check_empty(dataTfrRestCharge[item.bp_id][dataTfr[item.bp_id][sub_key]['id']]) : "-" }}                                                                                                                
                                        </td>

                                    <!-- <td v-else-if="column.Status && column.code == 'inclusion_001'" :code="column.code">   
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['transparence_smr']">
                                                <span v-html="IndicationJoInfo(dataTransparence[item.bp_id][sub_key]['transparence_smr']['indication_jo_info_smr'], 'ss', dataTransparence[item.bp_id][sub_key]['public_trs_date'])"></span>    
                                        </template>    
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'inclusion_002'" :code="column.code">   
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['transparence_smr']">
                                                <span v-html="IndicationJoInfo(dataTransparence[item.bp_id][sub_key]['transparence_smr']['indication_jo_info_smr'], 'coll', dataTransparence[item.bp_id][sub_key]['public_trs_date'])"></span>    
                                        </template>   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'inclusion_003'" :code="column.code"> 
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['transparence_smr']">
                                                <span v-html="IndicationJoInfo(dataTransparence[item.bp_id][sub_key]['transparence_smr']['indication_jo_info_smr'], 't2a', dataTransparence[item.bp_id][sub_key]['public_trs_date'])"></span>    
                                        </template>  
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'inclusion_004'" :code="column.code">   
                                        <template v-if="dataTransparence[item.bp_id] && dataTransparence[item.bp_id][sub_key] && dataTransparence[item.bp_id][sub_key]['transparence_smr']">
                                                <span v-html="IndicationJoInfo(dataTransparence[item.bp_id][sub_key]['transparence_smr']['indication_jo_info_smr'], 'retro', dataTransparence[item.bp_id][sub_key]['public_trs_date'])"></span>    
                                        </template>  
                                    </td>                                 -->
                                </template>

                            </tr>
                            </template>
                            </template>
                            <template v-else>
                                <tr>
                                    <td colspan="99">{{$t('No Data')}}</td>
                                </tr>
                            </template>
                        </tbody>

                    </table>
                </div>
                <!---- Area Table Result End----->
                    
                
            </div>
        </div> 

        <!-- <div v-else class="body-w100" >
            <div class="box-detail">
                <div class="wrapper-box-hta">
                    <h1 class="fa-pull-left mr-4 mt-1">{{$t('No Data')}}</h1>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>   -->
    </div>
    
                 
</template>

<script>

import IconTopTable from '@/components/elements/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue'; 
import ConfigColumnAdvance from '@/components/advanced_result/modal_config_column_advance.vue'; 
import ModalSaveKeyword from '@/components/elements/modal_save_keyword.vue';
//import Readmore from '@/components/elements/readmore.vue';
import { Base64 } from 'js-base64'
import { currentDate, isAdmin, hide_empty_row, format_french_number, number_format, icon_link, modifyPage, 
        check_empty, DDMMMYY, check_empty_with_locale, odd_even_border  } from '@/utils'; 
export default {
    name : "TablePrix",
    components: {    
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        ConfigColumnAdvance,
        ModalSaveKeyword,
        //Readmore  
    },
    props: { 
        main_page: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            actionName: "result_prix",               
            //dataTransparence : {},
            //dataCeesp : {},
            dataPrixVille : {},
            dataTfr : {},
            dataTfrRestCharge : {},
            options : {
                ss_coll : this.$route.query.options && this.$route.query.options.indexOf("ss_coll") > -1 ? true : false,
                parallel : this.$route.query.options && this.$route.query.options.indexOf("parallel") > -1 ? true : false,
                amm : this.$route.query.options && this.$route.query.options.indexOf("amm") > -1 ? true : false,
                latest_price : this.$route.query.options && this.$route.query.options.indexOf("latest_price") > -1 ? true : false,
                generique : this.$route.query.options && this.$route.query.options.indexOf("generique") > -1 ? true : false,
            },
            page:"prix"
            
        }
    },
    mounted() {
        console.log(' load tab prix...........')
        //search
        if(Object.keys(this.data).length === 0 && this.data.constructor === Object){
            this.request_data({mount : true, order_by: 'bp_presentation', sort: 'asc'}) 
            this.$store.dispatch(`advanced_result/prix_list_ss_coll`) 
        }else{
            this.watch_data(this.data)
        }
                 
                     
    },     
    methods: { 
        format_french_number,
        number_format,
        modifyPage,
        icon_link,
        check_empty,
        DDMMMYY,
        check_empty_with_locale,
        odd_even_border,
        request_data(param = {}){
            let paramObj = {...this.$route.params, ...this.$route.query}
            paramObj['base64Search'] = paramObj['base64Search'].replace('/\-/g', '+').replace('/_/g', '/') // eslint-disable-line
            if(!param['mount'] && this.sort_column && Object.keys(this.data).includes(this.sort_column['order_by'])){
                paramObj['order_by'] = this.sort_column['order_by']
            }
            else if(param['order_by']){
                paramObj['order_by'] = param['order_by']
            }
            else{
                paramObj['order_by'] = 'bp_presentation'
            }
            paramObj['sort'] = !param['mount'] && this.sort_column && this.sort_column['sort'] ? this.sort_column['sort'] : param['sort']
            if(param.page){
                paramObj['page'] = param.page
            }
            if(this.select_export_obj.isSelect){
                paramObj['prix_select_export'] = this.select_export_obj.listID
            }            
            if(this.main_page !== ""){
                paramObj['main_page'] = this.main_page
            }
            if(this.data_filter !== ""){
                paramObj['filter'] = this.data_filter
            }else if(this.data_simple_filters !== ""){
                paramObj['filter'] = Base64.encode(this.data_simple_filters);  
            }
            if(this.$route.query.keyword_id){
                paramObj['keyword_id'] = this.$route.query.keyword_id
            }
            if(param.export){
                delete param.export;
                this.$store.dispatch(`advanced_result/result_prix_excel`, [paramObj, currentDate()+"_advanced_prix" ] ) 
            }else{
                this.$store.dispatch(`advanced_result/result_prix`, paramObj ) 
            } 
        }, 
        selectExport(){
            this.$store.dispatch("advanced_result/prix_select_export", { key : "isSelect" , value : true})                   
            this.request_data() 
        },
        clearExport(){
            this.$store.dispatch("advanced_result/prix_select_export", "clear")                     
            this.request_data()
        },
        //IndicationJoInfo,
        hide_empty_row,
        //smr_type,    
        max_row(param){
            const temp = []
            temp.push(1)      
            if(param.biosimilaire){
                temp.push(param.biosimilaire.length)
            }    
            if(param.ansm){
                temp.push(param.ansm.length)
            } 
            if(param.boite_presentation_prix_atu){
                temp.push(param.boite_presentation_prix_atu.length)
            }  
            if(param.boite_presentation_forme_galenique_lists){
                temp.push(param.boite_presentation_forme_galenique_lists.length)
            }                     
            if(param.prix_evolution_ville){
                temp.push(this.array_prix_evolution_ville(param.prix_evolution_ville).length);
            }   
            if(param.prix_evolution_t2a){
                temp.push(param.prix_evolution_t2a.length);
            }  
            if(param.prix_evolution_retro){
                temp.push(param.prix_evolution_retro.length);
            } 
            if(param.tfr ){
                temp.push(this.array_tfr(param.tfr).length);                
            } 
            // if(param.transparence ){                
            //     temp.push(this.array_transparence(param.transparence).length);
            //     temp.push(this.array_ceesp(param.transparence).length);
            // }   
              
             //@todo push length of infojo , repertoire_generique,IndicationJoInfo
            return Math.max(...temp)
        },
        json_fields : function(param, key){ 
            let txt = ''
            if(param){   
                for(const val of param){
                    if(key == "ISP_libelle"){
                        txt += val[key] ?  "<p>"+ this.$t("isp."+val[key])+"</p>" : ""
                    }else{
                        txt += val[key] ? `<p>${val[key]}</p>` : ""
                    }                    
                }       
            }
            txt = this.empty(txt)
            return txt
        },        
        cip13(param){            
            let html = '';
            if (param.bp_cip13){
                html += param.amm_archivee ? this.$t("AMM archivée")+"<br/>" : ""
                html += param.amm_abrogee ? this.$t("AMM abrogée")+"<br/>" : ""
                html += param.amm_retiree ? this.$t("AMM abrogée")+"<br/>" : ""
                html += param.bp_cip13 ? param.bp_cip13  :""
            }
            return html ? html : "-"
        },   
        format_prix(number, decimals=0){
            // console.log(number)
            number = number * 1 
            let number_en =''
            let number_fr =''
            let number_format = ''

            if (number == 0 || number == -100){
                number_format = '-'
            }else{
                number = this.number_format(number,decimals)
                number_en = (''+number).replace(',', ' ')
                number_fr = (''+number).replace(',', '')
                number_format = this.$i18n.locale == 'fr' ? this.format_french_number(number_fr, decimals) : number_en
            }
            number_format = this.check_empty(number_format)
            return number_format

        },                   
        array_prix_evolution_ville : function(param){                 
            let result = []; 
            let prix_date_rad_ss = [];
            let prix_date_ins_ss = [];
            param.forEach((item) => {
                if(item.prix_date_rad_ss){
                    prix_date_rad_ss.push(new Date(item.prix_date_rad_ss))
                }
                if(item.prix_date_ins_ss){
                    prix_date_ins_ss.push(new Date(item.prix_date_ins_ss))
                }
            })            
            prix_date_rad_ss.sort()  
            Object.entries(param).forEach(([key,item]) => {
                
                let tempData = {...item};             
                if (key == 0 ){ 
                   result.push(tempData);
                }else if (key == param.length - 1 ){ 
                    result.push(tempData);
                }else if(item.prix_variation_ss != 0){ 
                    result.push(tempData);                    
                }
            })
            if(prix_date_rad_ss.length){
                prix_date_rad_ss.sort() 
                result[0]['prix_date_rad_ss'] = prix_date_rad_ss[0];
            }
            if(prix_date_ins_ss.length){
                prix_date_ins_ss.sort() 
                result[0]['prix_date_ins_ss'] = prix_date_ins_ss[0];
            }
           
            return result;
             
        }, 
        array_tfr : function(param){  
            const result = [];             
            for(const item of param){ 
                if(item.tfr_detail.length){
                    Object.entries(item.tfr_detail).forEach(([key2,item2]) => {
                        let tempData = {};  
                        if(key2 == 0){
                           tempData.id_tfr = item.id_tfr
                        }else{
                            delete item2.chk_tfr
                        }
                        tempData = {...tempData, ...item2}
                        result.push(tempData)
                    })    
                }else{
                    let tempData = {};  
                    tempData.id_tfr = item.id_tfr
                    result.push(tempData)
                }
            }
            return result;
             
        },
        array_tfr_rest_charge:function(param){ 
            const result = [];
            for (const item of param){
                result[item.tfr_detail_id] = item.rest_charge
            } 
            return result;
        }, 
        // array_transparence : function(param){
           
        //     const result = [];   

        //     for(const item of param){ 

        //         let tempTC = {...item}                
        //         delete tempTC.transparence_atc
        //         delete tempTC.ceesp
        //         delete tempTC.transparence_smr
        //         delete tempTC.audition
        //         const temp = []
        //         temp.push(1)
                
        //         if(item.transparence_atc){
        //             temp.push(item.transparence_atc.length)
        //         } 
        //         if(item.transparence_smr){
        //             temp.push(item.transparence_smr.length)
        //         }
        //         if(item.audition){
        //             temp.push(item.audition.length)
        //         }   
                
        //         const tempMax = Math.max(...temp)  

        //         for (let sub_key = 0; sub_key < tempMax; sub_key++) {
        //             let tempData = {}
        //             if(sub_key === 0){
        //                 tempData = {...tempTC}; 
        //             }
        //              tempData = {...tempData, public_trs_date : tempTC.trs_date}; 
        //             if(item.transparence_atc[sub_key]){
        //                 tempData = {...tempData, transparence_atc : item.transparence_atc[sub_key]}; 
        //             }
        //             if(item.transparence_smr[sub_key]){
        //                 tempData = {...tempData, transparence_smr : item.transparence_smr[sub_key]}; 
        //             }
        //             if(item.audition[sub_key]){
        //                 tempData = {...tempData, audition : item.audition[sub_key]}; 
        //             }
        //             // if(resultCeesp[sub_key]){
        //             //     tempData = {...tempData, ceesp : resultCeesp[sub_key]}; 
        //             // }
        //             result.push(tempData)
        //         } 
        //     }

        //     return result
             
        // },
        // array_ceesp : function(param){
           
        //     const result = [];   

        //     for(const item of param){ 
        //         const temp = []  
        //         const resultCeesp = []
        //         if(item.ceesp){
        //             temp.push(1)
                    
        //             for(const ceesp of item.ceesp){
        //                 let tempCeesp = {...ceesp}                
        //                 delete tempCeesp.ceesp_titleappreserve
        //                 delete tempCeesp.evaluation_economic 
                        
        //                 const rowCeesp = []
        //                 rowCeesp.push(1)
        //                 if(ceesp.ceesp_titleappreserve){
        //                     rowCeesp.push(ceesp.ceesp_titleappreserve.length)
        //                 }
        //                 if(ceesp.evaluation_economic){
        //                     rowCeesp.push(ceesp.evaluation_economic.length)
        //                 }  
        //                 const CeespMaxRow = Math.max(...rowCeesp) 
                        
        //                 for (let k = 0; k < CeespMaxRow; k++) {
        //                     let tempDataCeesp = {}
        //                     if(k === 0){
        //                         tempDataCeesp = {...tempCeesp}; 
        //                     }
        //                     if(ceesp.ceesp_titleappreserve[k]){
        //                         tempDataCeesp = {...tempDataCeesp, ceesp_titleappreserve : ceesp.ceesp_titleappreserve[k]}; 
        //                     }
        //                     if(ceesp.evaluation_economic[k]){
        //                         tempDataCeesp = {...tempDataCeesp, evaluation_economic : ceesp.evaluation_economic[k]}; 
        //                     } 
        //                     resultCeesp.push(tempDataCeesp)
        //                 }
        //             }

        //             temp.push(resultCeesp.length)
        //         } 
                
                
        //         const tempMax = Math.max(...temp)  

        //         for (let sub_key = 0; sub_key < tempMax; sub_key++) {                   
                                      
        //             if(resultCeesp[sub_key]){
        //                 result.push(resultCeesp[sub_key]); 
        //             }
                    
        //         } 
        //     }

        //     return result
             
        // },
        changeOptions(){

            const tempOption = []
            Object.entries(this.options).forEach(([key,value])=>{
                if(value){
                    tempOption.push(key)
                }
            })          
            if(tempOption.length){
                this.$router.push({ query: Object.assign({}, this.$route.query, { options : tempOption.join(",") }) }); 
            }else{ 
                let query = Object.assign({}, this.$route.query);
                delete query.options;
                this.$router.push({ query });  
            } 
        },
        colorRow(item){
            let backgroundClass = ""
            if(this.prix_list_ss_coll.includes(item.bp_id)){
                backgroundClass = " bg_highlight1"
            }else if(RegExp('^490').test(item.bp_cip7) || RegExp('^495').test(item.bp_cip7) || RegExp('^34009490').test(item.bp_cip7) || RegExp('^34009495').test(item.bp_cip7) ){
                backgroundClass = " bg_highlight2"
            }else if(item.amm_archivee || item.amm_abrogee || item.amm_retiree){
                backgroundClass = " bg_highlight3"
            }else if(item.bp_statut_generique === "Générique" || item.bp_statut_generique === "Similaire"){
                backgroundClass = " bg_highlight5"
            }
            return backgroundClass
        },
        watch_data(newData){
            let tempPrixVille = {} 
            let resultTfr = {}
            let resultTfrRestCharge = {}
            //let resultTrs = {}
            //let resultCeesp = {}
            if(newData.data){
                for(let item of newData.data){  
                    if (item.prix_evolution_ville){ 
                        tempPrixVille[item.bp_id] = this.array_prix_evolution_ville(item.prix_evolution_ville)
                    }
                    if (item.tfr){ 
                        resultTfr[item.bp_id] = this.array_tfr(item.tfr)
                    }
                    //if (item.transparence){                 
                        //resultTrs[item.bp_id] = this.array_transparence(item.transparence)    
                        //resultCeesp[item.bp_id] = this.array_ceesp(item.transparence)                      
                    //}                 
                    if (item.tfr_rest_charge){
                        resultTfrRestCharge[item.bp_id] = this.array_tfr_rest_charge(item.tfr_rest_charge)
                    } 
                    
                } 
                this.dataPrixVille = tempPrixVille           
                this.dataTfr = resultTfr
                this.dataTfrRestCharge = resultTfrRestCharge
                //this.dataTransparence = resultTrs
                //this.dataCeesp= resultCeesp
                //console.log(resultTfrRestCharge)
            }
        }


    },
    computed: {
        isAdmin,
        data(){
            return this.$store.getters['advanced_result/prix_data'];
        },
        prix_list_ss_coll(){
            return this.$store.getters['advanced_result/prix_list_ss_coll'];
        },
        orderColumn(){
            const columns = this.$store.getters['advanced_result/prix_column']; 
            let tempColumns = []
            for (const item of columns) {
                tempColumns = [...tempColumns, ...item.column]
            } 
            return tempColumns               
        },
        default_column(){
            const columns = this.$store.getters['advanced_result/prix_default_column']; 
            let tempColumns = []
            for (const item of columns) {
                tempColumns = [...tempColumns, ...item.column]
            } 
            return tempColumns               
        },                  
        count() {
            return this.$store.getters['advanced_result/prix_count']
        },       
        select_export: {
            set(val){ this.$store.dispatch("advanced_result/prix_select_export", {key: 'listID', value: val}) },
            get(){ 
                if(this.$store.getters['advanced_result/prix_select_export'].checkAll){
                    return true
                }else{
                    return this.$store.getters['advanced_result/prix_select_export'] ? this.$store.getters['advanced_result/prix_select_export'].listID : null
                }
            }
        }, 
        select_export_obj(){
            return this.$store.getters['advanced_result/prix_select_export']
        },
        data_filter(){
            return this.$store.getters['advanced_result/data_filter']
        },
        data_simple_filters(){
            return this.$store.getters['result/filters'];
        },
        sort_column(){
            return this.$store.getters['advanced_result/sort_column']
        },
        type(){
            return this.$store.getters['advanced_result/type'] 
        },
        column(){
            return this.$store.getters['advanced_result/prix_column']                       
        },
        reset_column(){
            return this.$store.getters['advanced_result/prix_reset_column']                       
        },
    },
    watch : {
        data : function(newData){ 
            console.log('watch prix...............')
            this.watch_data(newData);
        },
        $route : function(to, from) {
            if( (to.path === from.path) && (to.fullPath !== from.fullPath) ){
                console.log('route change'); 
               
                let old_arr_queryString = []
                if(from.query.options){
                    old_arr_queryString = from.query.options.split(",");
                }

                let arr_queryString = []
                if(to.query.options){
                    arr_queryString = to.query.options.split(",");
                }
                if(arr_queryString.length !== old_arr_queryString.length){
                    let tempOption = {...this.options}
                    for (const x in tempOption) { 
                        tempOption[x] = false
                    }
                    
                    arr_queryString.forEach((query) => {
                        if(tempOption[query]  !== undefined){
                        tempOption[query] = true 
                        }
                    })

                    this.options = tempOption                
                    this.request_data()
                }
                
            }
        }
    },
    updated() {
        this.hide_empty_row(`#${this.actionName}`)
    },
    unmounted () {
    }
}
</script>

<style scoped> 
.box-fix-left{ padding-top:133px}

@media only screen and (max-width: 862px){
    .main-body .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .main-body .content-table-results{
        max-height: 50vh;
    }
}
@media only screen and (min-width: 1342px){
    .main-body .content-table-results{
        max-height: 55vh;
    }
}
@media only screen and (min-height: 863px){
    .main-body .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .main-body .content-table-results{
        max-height: 75vh;
    }
}
</style>