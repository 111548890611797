<template>
    <div>
        <TabClinicalTrialsGraph v-if="isUserInter && !$route.query.zoom" @request_data="request_data" />        
        <div :class="'body-w100' + (isUserInter ? ' body-custom' : '')" v-if="data.data && data.data.length > 0">
            <div class="box-detail">
                <!-- <div class="wrapper-box-hta">
                    <h1 class="fa-pull-left mr-4 mt-1">Reimbursement Status</h1>
                    <div class="clearfix"></div>
                </div>  -->
                <ModalConfigColumn :page="page" :type="type" :column="orderColumn" :reset_column="reset_column" />
                <ModalSaveKeyword :type="type" />
                <IconTopTable @onRequest="request_data" :page="page" @onSelectExport="selectExport" @onClearExport="clearExport" />
                <div class="content-table-results mt-2">
                    <table class="table table-fixed table-stripes" :id="page" >
                        <ColumnColGroup :page="page" :column="orderColumn" :default_column="default_column"/>
                        <ColumnThead :page="page" :main_page="main_page" :column="orderColumn" :default_column="default_column"/>  
                        
                        <tbody> 
                            <template v-for="(item, key) in data.data" >
                            <tr v-for="(v, i) in max_row(item)" :key="key+'_'+v" :class="(key%2==0 ? 'odd' : 'even')+(i===0 ? '' : ' noborder')" >                
                                
                                <td>
                                    <template v-if="i===0">
                                        <label class="table-check">
                                            <input type="checkbox"  v-model="select_export" :value="item.nct_id">
                                            <span class="checkbox-table"></span>
                                        </label>
                                    </template>
                                </td>
                                <!-- <td>
                                    <template v-if="i===0">
                                    <div>
                                        <em class="icon-edit"></em>
                                    </div>
                                    </template>
                                </td> -->
                                <template v-for="(column) in orderColumn" :key="column.code" >                                               
                                    <td v-if="column.Status && column.code == 'essais_clinique_001'" :code="column.code">                                                 
                                        <span v-if="i===0" @click="essais_clinical_popup(item.nct_id, 'essais_clinique')" data-bs-toggle="modal" data-bs-target="#modal-essais-clinique" style="cursor:pointer;">{{ check_empty(item.nct_id) }}</span>                                                     
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_002'" :code="column.code">                                                 
                                        <template v-if="i===0">{{check_empty(item.overall_status)}}</template>                                                   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_003'" :code="column.code">                                                 
                                        <template v-if="i===0"><Readmore :longText="item.brief_title"/></template>                                                   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_004'" :code="column.code">                                                 
                                        <template v-if="i===0">{{check_empty(item.acronym)}}</template>                                                   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_005'" :code="column.code">                                                 
                                        <template v-if="i===0">{{check_empty(DDMMMYY(item.start_date))}}</template>                                                   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_006'" :code="column.code">                                                 
                                        <template v-if="i===0">{{check_empty(DDMMMYY(item.completion_date))}}</template>                                                   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_007'" :code="column.code">                                                 
                                        <template v-if="i===0">{{check_empty(item.phase)}}</template>                                                   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_008'" :code="column.code">  
                                        <template v-if="i===0">{{ check_empty(array_join(item.intervention, 'name'))}}</template>                                                
                                    </td>
                                    <!-- <td v-else-if="column.Status && column.code == 'essais_clinique_009'" :code="column.code">                                                 
                                        <template v-if="item.condition[i]">{{ check_empty(item.condition[i]['name'])}}</template>                                               
                                    </td> -->
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_017'" :code="column.code">                                                 
                                        <template v-if="i===0"><Readmore :longText="display_design_outcome(item.design_outcome, 'primary')" textAlign="center" /></template>                                                   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_018'" :code="column.code">                                                 
                                        <template v-if="i===0"><Readmore :longText="display_design_outcome(item.design_outcome, 'secondary')" textAlign="center" /></template>                                                   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_031'" :code="column.code">                                                 
                                        <template v-if="i===0"><Readmore :longText="display_design_outcome(item.design_outcome, null, true)" textAlign="center" /></template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_028'" :code="column.code">                                                 
                                        <template v-if="i===0"><Readmore :longText="display_design_outcome(item.design_outcome)" textAlign="center" /></template>                                                   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_029'" :code="column.code">  
                                        <template v-if="i===0"> <Readmore :longText="display_therapeutical_area(item.unique_indices)" textAlign="center" /></template>                                                
                                    </td>
                                    <!-- <td v-else-if="column.Status && column.code == 'essais_clinique_010'" :code="column.code">                                                 
                                        <template v-if="item.design_outcome[i]">{{ check_empty(item.design_outcome[i]['measure'])}}</template>                                                   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_011'" :code="column.code">                                                 
                                        <template v-if="item.design_outcome[i]">{{ check_empty(item.design_outcome[i]['time_frame'])}}</template>                                                  
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_012'" :code="column.code">                                                 
                                        <template v-if="item.design_outcome[i]"><Readmore :longText="item.design_outcome[i]['description'])"/></template>                                              
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_013'" :code="column.code">                                                 
                                        <template v-if="item.design_outcome[i]">{{ check_empty(item.design_outcome[i]['outcome_type'])}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_014'" :code="column.code">                                                 
                                        <template v-if="item.id_information[i]">{{ check_empty(item.id_information[i]['id_value'])}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_015'" :code="column.code">                                                 
                                        <template v-if="item.design[i]">{{ check_empty(item.design[i]['primary_purpose'])}}</template>
                                    </td>  -->

                                    <template v-else-if="column.Status && column.code == 'essais_clinique_016'">
                                        <td :key="column.code+'_1'" code="essais_clinique_0161" class="hta-separator">                                                 
                                            <template v-if="item.transparence_smr && item.transparence_smr[i] && item.transparence_smr[i]['transparence']">
                                                {{check_empty(item.transparence_smr[i]['transparence']['trs_specialities'])}}
                                            </template> 
                                            <span v-else> - </span>
                                        </td>   
                                        <td :key="column.code+'_2'" code="essais_clinique_0162">                                                 
                                            <template v-if="item.transparence_smr && item.transparence_smr[i]">
                                                <template v-if="$i18n.locale !== 'fr'"> 
                                                    <Readmore :longText="item.transparence_smr[i]['smr_title_en']" />
                                                </template>
                                                <template v-else>  
                                                    <Readmore :longText="item.transparence_smr[i]['smr_title']" />
                                                </template> 
                                            </template> 
                                            <span v-else> - </span>
                                        </td> 
                                        <td :key="column.code+'_3'" code="essais_clinique_0163">                                                 
                                            <template v-if="item.transparence_smr && item.transparence_smr[i]">                                                 
                                                <div @click="hta_status_modal(item.transparence_smr[i].drug_center)" data-bs-toggle="modal" data-bs-target="#modal-hta-status" style="cursor: pointer;">
                                                <span  v-html="smr_type(item.transparence_smr[i]['smr_type'])"></span> 
                                                <span v-if="item.transparence_smr[i]['asmr_obtenue']"> / {{ item.transparence_smr[i]['asmr_obtenue'] }}</span>     
                                                </div>                  
                                            </template>
                                            <span v-else> - </span>
                                        </td>
                                        <td :key="column.code+'_4'" code="essais_clinique_0164">
                                            <template v-if="item.transparence_smr && item.transparence_smr[i]">
                                                <span @click="essais_clinical_popup(item.transparence_smr[i]['id'], 'transparence_smr')" data-bs-toggle="modal" data-bs-target="#modal-essais-clinique" style="cursor:pointer;" v-html="submitted(item.transparence_smr[i])"></span>
                                            </template>
                                            <span v-else> - </span>
                                        </td> 
                                        <td :key="column.code+'_5'" code="essais_clinique_0165">                                                 
                                            <template v-if="item.transparence_smr && item.transparence_smr[i] && item.transparence_smr[i]['transparence']">
                                                <template v-if="$i18n.locale !== 'fr'"> 
                                                    {{check_empty(item.transparence_smr[i]['transparence']['trs_type_demandes_en'])}}
                                                </template>
                                                <template v-else>  
                                                    {{check_empty(item.transparence_smr[i]['transparence']['trs_type_demandes'])}}
                                                </template> 
                                            </template> 
                                            <span v-else> - </span>
                                        </td> 
                                        <td :key="column.code+'_6'" code="essais_clinique_0166">                                                 
                                            <template v-if="item.transparence_smr && item.transparence_smr[i] && item.transparence_smr[i]['transparence']">
                                                {{check_empty(DDMMMYY(item.transparence_smr[i]['transparence']['trs_date']))}}
                                            </template> 
                                            <span v-else> - </span>
                                        </td>
                                    </template>
                                    <template v-else-if="column.Status && column.code == 'essais_clinique_030'">
                                        <td :key="column.code+'_4'" code="essais_clinique_0304" class="hta-separator">                                                 
                                            <template v-if="item.gba_populations && item.gba_populations[i] && item.gba_populations[i]['gba']">
                                                {{check_empty(item.gba_populations[i]['gba']['name'])}}
                                            </template> 
                                            <span v-else> - </span>
                                        </td>
                                        <td :key="column.code+'_1'" code="essais_clinique_0301">                                                 
                                            <template v-if="item.gba_populations && item.gba_populations[i]">
                                                {{check_empty(item.gba_populations[i]['patient_population_title'])}}
                                            </template> 
                                            <span v-else> - </span>
                                        </td>   
                                        <td :key="column.code+'_2'" code="essais_clinique_0302">                                                 
                                            <template v-if="item.gba_populations && item.gba_populations[i]">
                                                <span @click="hta_status_modal(prepare_gba_item(item,i))" data-bs-toggle="modal" data-bs-target="#modal-hta-status" style="cursor: pointer;">
                                                    {{check_empty(item.gba_populations[i]['gba_addition_benefits_gba_added_benefit_id_text'])}}
                                                </span>
                                            </template> 
                                            <span v-else> - </span>
                                        </td> 
                                        <td :key="column.code+'_3'" code="essais_clinique_0303">                                                 
                                            <template v-if="item.gba_populations && item.gba_populations[i]">                                      
                                                <span @click="essais_clinical_popup(item.gba_populations[i]['id'], 'gba_populations')" data-bs-toggle="modal" data-bs-target="#modal-essais-clinique" style="cursor: pointer;" v-html="display_gba_other_studies(item.gba_populations[i]['nct_numbers'])"></span>
                                            </template>
                                            <span v-else> - </span>
                                        </td>
                                        <td :key="column.code+'_5'" code="essais_clinique_0305">                                                 
                                            <template v-if="item.gba_populations && item.gba_populations[i] && item.gba_populations[i]['gba']">
                                                {{check_empty(DDMMMYY(item.gba_populations[i]['gba']['deadline_resolution']))}}
                                            </template> 
                                            <span v-else> - </span>
                                        </td>
                                    </template>
                                    <!-- <td v-else-if="column.Status && column.code == 'essais_clinique_021'" :code="column.code">     
                                        <template v-if="i===0"><span v-html="array(item.drug_center && item.drug_center.indexation ? item.drug_center.indexation : ['-'])"></span></template>                                                   
                                    </td> -->
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_022'" :code="column.code">                                                 
                                        <template v-if="i===0">{{ check_empty(item.trial_name)}}</template>                                                   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_023'" :code="column.code">                                                 
                                        <template v-if="i===0">{{ item.ec_type ? $t(item.ec_type) : "-" }}</template>                                                   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_024'" :code="column.code">                                                 
                                        <template v-if="i===0">{{ item.ec_comparateur ? $t(item.ec_comparateur) : "-" }}</template>                                                   
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_025'" :code="column.code">  
                                        <template v-if="i===0">{{ check_empty(array_join(item.design, 'masking'))}}</template>                                                
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_026'" :code="column.code">  
                                        <template v-if="i===0">{{ check_empty(array_join(item.design, 'allocation'))}}</template>                                                
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'essais_clinique_027'" :code="column.code">                                                 
                                        <template v-if="i===0">{{ check_empty(item.enrollment)}}</template>                                                   
                                    </td>
                                                                      
                                </template>
                            </tr>
                            </template>
                        </tbody>

                    </table>
                </div>
                <!---- Area Table Result End----->
                    
                
            </div>
        </div> 

        <div v-else class="body-w100" >
            <div class="box-detail">
                <div class="wrapper-box-hta">
                    <h1 class="fa-pull-left mr-4 mt-1">{{$t('No Data')}}</h1>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>  
        <ModalHTAstatus :item="item_hta_status" type="Transparence"/> 
        <ModalEssaisClinique :id="nct_id_popup" :agency="model" />
    </div>
    
                 
</template>

<script>

import IconTopTable from '@/components/elements/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue'; 
import ModalConfigColumn from '@/components/elements/modal_config_column.vue';
import ModalSaveKeyword from '@/components/elements/modal_save_keyword.vue';
import Readmore from '@/components/elements/readmore.vue';
import { currentDate, hide_empty_row, smr_type, DDMMMYY, check_empty, isUserInter } from '@/utils'; 
import ModalHTAstatus from '@/components/result/modal_hta_status.vue';
import ModalEssaisClinique from '@/components/elements/modal_essais_clinique.vue'
import TabClinicalTrialsGraph from '@/components/result/tab_clinical_trials_graph.vue'
import { Base64 } from 'js-base64'
export default {
    name : "TableEssaisClinique",
    components: {    
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        ModalConfigColumn,
        ModalSaveKeyword,
        Readmore,
        ModalHTAstatus,
        ModalEssaisClinique,
        TabClinicalTrialsGraph
    },
    props: { 
        main_page: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            page : "essais_clinique",
            nct_id_popup : 0,
            model : 'essais_clinique',
            item_hta_status : {},
        }
    },
    mounted() {
        console.log(' load tab essais_clinique...........')
        //search
        if(Object.keys(this.data).length === 0 && this.data.constructor === Object){
            this.request_data({mount : true, order_by : 'nct_id', sort : 'desc'}) 
        }                 
       
    },     
    methods: { 
        request_data(param = {}){
            console.log('REQUEST DATA : ', param, this.sort_column)
            let paramObj = {...this.$route.params, ...this.$route.query}
            let keywords = Base64.decode(paramObj['base64Search']);
            let keywordsArr = keywords.split('|')

            if(param.graph_filter){
                for( var i = 0; i < keywordsArr.length; i++){              
                    if ( (keywordsArr[i].includes('therapeutic') && 'therapeutic' in param) || 
                    (keywordsArr[i].includes('name') && 'name' in param) || 
                    (keywordsArr[i].includes('agency') && 'agency' in param)
                    ) { 
                        keywordsArr.splice(i, 1); 
                        i--; 
                    }
                }
                keywords = keywordsArr.join('|')
                paramObj['graph_filter'] = true
                this.graph_filter_callback(true)
            }

            if('therapeutic' in param) {   
                paramObj['base64Search'] = Base64.encode('therapeutic=' + param.therapeutic + '|' + keywords)
            } else if('name' in param) {
                let brandname = param.name.includes('+') ? param.name.split(')') : param.name.split(' ')
                paramObj['base64Search'] = Base64.encode('name=' + brandname[0] + '|' + keywords)
            } else if('agency' in param) {
                paramObj['base64Search'] = Base64.encode('agency=' + param.agency + '|' + keywords)
            }

            if('status' in param)
                paramObj['base64Search'] = Base64.encode('status=' + param.status + '|' + keywords)

            paramObj['base64Search'] = paramObj['base64Search'].replace('/\-/g', '+').replace('/_/g', '/') // eslint-disable-line
            this.$router.replace({ query: Object.assign({}, this.$route.query), params: {base64Search: paramObj['base64Search']} });

            if(!param['mount'] && this.sort_column && Object.keys(this.data).includes(this.sort_column['order_by'])){
                paramObj['order_by'] = this.sort_column['order_by']
            }
            else if(param['order_by']){
                paramObj['order_by'] = param['order_by']
            }
            else{
                paramObj['order_by'] = 'nct_id'
            }
            paramObj['sort'] = !param['mount'] && this.sort_column && this.sort_column['sort'] ? this.sort_column['sort'] : param['sort']
            if(param.page){
                paramObj['page'] = param.page
            }
            if(this.select_export_obj.isSelect){
                paramObj['select_export_nct_numbers'] = this.select_export_obj.listID
            }            
            if(this.main_page !== ""){
                paramObj['main_page'] = this.main_page
            }  
            if(param.export){
                delete param.export;
                this.$store.dispatch(`essais_clinique/result_essais_clinique_excel`, [paramObj, currentDate()+"_advanced_essais_clinique" ] ) 
            }else{
                this.$store.dispatch(`essais_clinique/result_essais_clinique`, paramObj ) 
            } 
        },

        /**
         * Event callback if graph filter is applied (from component TabClinicalTrialsGraph)
         */
        graph_filter_callback(graph_filter){
            this.$emit('graph_filter_callback', {graph_filter});
        },
        hide_empty_row, 
        smr_type,
        selectExport(){
            this.$store.dispatch("essais_clinique/select_export", { key : "isSelect" , value : true})                     
            this.request_data() 
        },
        clearExport(){
            this.$store.dispatch("essais_clinique/select_export", "clear")                     
            this.request_data()
        },
        DDMMMYY,
        check_empty,
        hta_status_modal(item){
            this.item_hta_status = item
        },
        prepare_gba_item(item,index) {
            const gba_populations = item.gba_populations

            return {
                name: item.categorie_name,
                gba: {
                    gba_addition_benefit: gba_populations[index]
                },
                agency_model: 'Gba',
                icon_flags : gba_populations[index].gba.drug_center.icon_flags,
                agency_type : gba_populations[index].gba.drug_center.agency_type,
                acronym : gba_populations[index].gba.drug_center.acronym,
                hta_process_date : gba_populations[index].gba.drug_center.hta_process_date,
                hta_decision : gba_populations[index].gba.drug_center.hta_decision,
                rembursement_process_date : gba_populations[index].gba.drug_center.rembursement_process_date,
                reimbursement_decision : gba_populations[index].gba.drug_center.reimbursement_decision,
                drug_id : gba_populations[index].gba.drug_center.drug_id,
                decision_date_num: gba_populations[index].gba.drug_center.decision_date_num,
                decision: gba_populations[index].gba.drug_center.decision,
                rationale_and_comments_html_en: gba_populations[index].gba.drug_center.rationale_and_comments_html_en,
                rationale_and_comments_html_original: gba_populations[index].gba.drug_center.rationale_and_comments_html_original,
                prioritis_hta_index: gba_populations[index].gba.drug_center.prioritis_hta_index,
                target_population: gba_populations[index].gba.drug_center.target_population,
                target_population_en: gba_populations[index].gba.drug_center.target_population_en,
                target_population_de: gba_populations[index].gba.drug_center.target_population_de,
                prescription_and_delivery_conditions: gba_populations[index].gba.drug_center.prescription_and_delivery_conditions,
                prescription_and_delivery_conditions_en: gba_populations[index].gba.drug_center.prescription_and_delivery_conditions_en,
                prescription_and_delivery_conditions_de: gba_populations[index].gba.drug_center.prescription_and_delivery_conditions_de,
                access_scheme: gba_populations[index].gba.drug_center.access_scheme,
                access_scheme_en: gba_populations[index].gba.drug_center.access_scheme_en,
                access_scheme_de: gba_populations[index].gba.drug_center.access_scheme_de,
                check_outcome_level: gba_populations[index].gba.drug_center.check_outcome_level,
                check_outcome_level_en: gba_populations[index].gba.drug_center.check_outcome_level_en,
                check_outcome_level_de: gba_populations[index].gba.drug_center.check_outcome_level_de,
                check_prescription_and_delivery_conditions: gba_populations[index].gba.drug_center.check_prescription_and_delivery_conditions
            }
        },
        essais_clinical_popup(id, model){
            this.model = model
            this.nct_id_popup = id
        },
        array_join(datas, key){
            const arr = [];
            if(datas){        
                datas.forEach((val) => { 
                    if(val && val[key]){
                        arr.push(val[key]) 
                    }   
                })     
            }
            return arr.join(", ")
        },
        submitted(datas){
            const arr = [];
            if(datas && datas['ctgov_study']){        
                datas['ctgov_study'].forEach((val) => { 
                    let linkedNct = this.data.data.find(nct => nct.nct_id === val['nct_id'])
                    if(linkedNct)
                        arr.push("<div>" + linkedNct.trial_name + ', ' + linkedNct.nct_id + (linkedNct.phase ? ", " + linkedNct.phase : '') + "</div>");                
                })     
            }
            return arr.join("")
        },
        display_design_outcome(param, outcome_type = null, gettype = false){
            let measure = "";
            let i = 0;
            param.forEach((item) => {
                if(item.outcome_type === outcome_type || !outcome_type){
                    let value = (gettype) ? item.outcome_type : item.measure
                    if(i < 3){
                        measure += "<p  style=\"margin-bottom: 5px\">" + value + "</p>";                         
                    }
                    i++;
                }
            })  
            return measure
        },
        display_therapeutical_area(param){
            let area = "";
            param.forEach((item) => {
                area += "<p  style=\"margin-bottom: 5px;\">"+item.indexation+"</p>";
            })  
            return area
        },
        display_gba_other_studies(param) {
            let ncts = "";
            param.forEach((item) => {
                let linkedNct = this.data.data.find(nct => nct.nct_id === item.nct_number)
                if(linkedNct)
                    ncts += "<p  style=\"margin-bottom: 5px;\">"+linkedNct.trial_name+", "+item.nct_number+", "+linkedNct.phase+"</p>";                         
            })  
            return ncts
        },
        max_row(param){
            const temp = []
            temp.push(1)      
              
            // if(param.id_information){
            //     temp.push(param.id_information.length)
            // }
            if(param.condition){
                temp.push(param.condition.length)
            }
            // if(param.design_outcome){
            //     temp.push(param.design_outcome.length)
            // }
            // if(param.design){
            //     temp.push(param.design.length)
            // }
            if(param.transparence_smr){
                temp.push(param.transparence_smr.length)
            }            
            return Math.max(...temp)
        },
    },
    computed: {
        data(){
            return this.$store.getters['essais_clinique/data'];
        },
        orderColumn(){
            return this.$store.getters['essais_clinique/column'];
        },
        default_column(){
            return this.$store.getters['essais_clinique/default_column'];
        },
        type(){
            return this.$store.getters['essais_clinique/type'] 
        },
        reset_column(){
            return this.$store.getters['essais_clinique/reset_column']                       
        }, 
        select_export: {
            set(val){ this.$store.dispatch("essais_clinique/select_export", {key: 'listID', value: val}) },
            get(){ 
                if(this.$store.getters['essais_clinique/select_export'].checkAll){
                    return true
                }else{
                    return this.$store.getters['essais_clinique/select_export'] ? this.$store.getters['essais_clinique/select_export'].listID : null
                }
            }
        }, 
        select_export_obj(){
            return this.$store.getters['essais_clinique/select_export']
        },
        isUserInter,
        sort_column(){
            return this.$store.getters['essais_clinique/sort_column']
        }
    },
    updated() {
        this.hide_empty_row(`#${this.page}`)
    },
    unmounted () {
    }
}
</script>

<style scoped> 

/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>