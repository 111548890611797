<template>
    <teleport to="body">
    <div class="modal-column modal fade modal-custom" id="modal-config-column" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content odal-custom p-4">
                <div>
                    <h5 class="modal-title">{{$t('Choisir les colonnes à afficher :')}}</h5>
                    <a type="button" class="close" data-bs-dismiss="modal" aria-label="Close" style="opacity: 1;">
                        <span class="close-column"></span>
                    </a>
                </div>
                <div class="modal-body p-0">
                    <div class="mb-4">
                        1. {{$t('Sélectionnez les rubriques que vous désirez afficher dans le tableau.')}}<br>
                        2. {{$t('Faites glisser les rubriques pour réorganiser les colonnes du tableau.')}}
                    </div>
                    
                    <div class="row">
                        <div class="col-6">

                            <draggable :list="draggableItems1" group="people" class="checkbox-list drag-item-list" v-bind="dragOptions" @end="onEnd" itemKey="code">                     
                                <template #item="{ element }">
                                <li class="drag-item">     
                                    <label class="group-checkbox" style="cursor: pointer;">
                                        <div class="dd-handle move">
                                            <div class="icon-draggable"></div>
                                        </div>
                                        <input type="checkbox" v-model="itemActive" :value="element.code" :id="element.code" >
                                        <span class="icon-checkbox"  ></span>
                                        <div>{{element.text}}</div> 
                                        
                                    </label>
                                </li>
                                </template>
                            </draggable>

                            
                        </div>
                        <div class="col-6">
                            <draggable :list="draggableItems2" group="people" class="checkbox-list drag-item-list" v-bind="dragOptions" @end="onEnd" itemKey="code">                     
                                <template #item="{ element }">
                                <li class="drag-item">     
                                    <label class="group-checkbox" style="cursor: pointer;">
                                        <div class="dd-handle move">
                                            <div class="icon-draggable"></div>
                                        </div>
                                        <input type="checkbox" v-model="itemActive" :value="element.code" :id="element.code">
                                        <span class="icon-checkbox"  ></span>
                                        <div>{{element.text}}</div> 
                                        
                                    </label>
                                </li>
                                </template>
                            </draggable>
                            
                        </div>
                    </div>

                </div>
                <div class="bt-footer">
                    <button type="button" class="btn-border-orange" style=" margin-right: 40px" @click="resetColumn">{{$t('modal_column.reset')}}</button>
                    <button type="button" :class="'btn-orange' + (canSave ? '' : ' btn-muted pointerNone')" @click="saveConfigColumn">{{$t('modal_column.save')}}</button>
                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>

<script>
import { Modal } from 'bootstrap'
import draggable from 'vuedraggable' 
export default {
    name : "ModalConfigColumn",
    components:{
        draggable
    }, 
    data() {
        return { 
            draggableItems1 : [],
            draggableItems2 : [],
            itemActive : [],
            canSave : false,
            modal : null
        }
    },
    props: { 
        page: {
            type: String,
            required: true,
            default: "",
        },        
        column: Array,
        reset_column: Array,
        type: String
    },
    mounted() {
        this.setItemDraggable(this.column);
        this.modal = new Modal(document.getElementById('modal-config-column'));
    },
    methods: {
        
        setItemDraggable(data_column){
            this.itemActive = []
            const itemActive = []
            for(let i in data_column){ 
                if(data_column[i]['Status']){
                    itemActive.push(data_column[i].code)
                }
            }
            this.itemActive = itemActive;
            const half = Math.ceil(data_column.length / 2)
            const half_last = data_column.length - half
            this.draggableItems1 = data_column.slice(0, half)
            this.draggableItems2 = data_column.slice(-half_last) 
        },
        resetColumn(){
            this.setItemDraggable(this.reset_column);
        },          
        saveConfigColumn(){
            if(this.canSave){
                let objColumn = {};
                let tempData = this.draggableItems1.concat(this.draggableItems2); 
                tempData.forEach((val) => {
                    if(this.itemActive.includes(val.code)){
                        objColumn[val.code] = true;
                    }else{
                        objColumn[val.code] = false;
                    }                    
                });
                if(this.page == "essais_clinique"){
                    this.$store.dispatch("essais_clinique/saveColumn", { column : objColumn, type : this.type}).then(()=> {
                        this.modal.hide();
                    });
                }else if(this.page == "report"){
                    this.$store.dispatch("report/saveColumn", { column : objColumn, type : this.type} ).then(()=> {
                        this.modal.hide();
                    });
                }else{
                    this.$store.dispatch("advanced_result/saveColumn", { column : objColumn, type : this.type} ).then(()=> {
                        this.modal.hide();
                    });
                }                
                console.log('saveConfigColumn')
            }
        },
        checkCanSave(){
            let temp = {};
            let temp2 = {};
            this.column.forEach((val) => {
                temp[val.code] = val.Status;
            }); 

            let tempData = this.draggableItems1.concat(this.draggableItems2); 
            tempData.forEach((val) => {
                if(this.itemActive.includes(val.code)){
                    temp2[val.code] = true;
                }else{
                    temp2[val.code] = false;
                }
                
            });
            if(JSON.stringify(temp) !== JSON.stringify(temp2)){
                this.canSave = true
            }else{
                this.canSave = false
            }
        },
        onEnd(){
            this.checkCanSave()
            console.log('onEnd')
        }
    },
    computed: { 
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    },
    watch: {            
        itemActive: function(val) {
            // alert('watch  --- itemActive' + val);
            console.log('watch  --- itemActive' + val)
            this.checkCanSave()
        },
        column: function() {
            this.setItemDraggable(this.column);
        },
    },
   
}
</script>

<style scoped>
 
</style>